<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
    rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>

<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 85%;">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none; ">
                        <div class="cardicono rounded">
                            <i class="pi pi-users mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header style="left: 11%; top: 5%;">
                            <div style="display:inline">Listado de</div>
                            <div style="color:blueviolet;display:inline;" class="ml-3">Hijos Con Discapacidad</div>
                        </header>
                        <div class="row d-flex justify-content-end">
                            <div class="col-2">
                                <p-button label="Agregar" icon="pi pi-plus-circle" iconPos="right"
                                    (click)="mostrarModalAgregar();" pTooltip="Registrar hijo">
                                </p-button>
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3">
                            <p-table #dt1 [value]="listsHijosChofer" [tableStyle]="{'min-width': '50rem'}"
                                styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                                responsiveLayout="scroll" [globalFilterFields]="['nombre_chofer','des_curp']"
                                [paginator]="true" [rows]="30" [rowsPerPageOptions]="[10,15,20,30,50]"
                                [showCurrentPageReport]="true" [resizableColumns]="false"
                                currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                                [loading]="loading">
                                <ng-template pTemplate="caption">
                                    <div class="flex justify-content-end">
                                        <span class="p-input-icon-left p-ml-auto">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" size="40"
                                                (input)="applyFilterGlobal($event, 'contains')" placeholder="Buscar Colaborador, CURP" />
                                        </span>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="idx" class="text-center">#<p-sortIcon
                                                field="idx"></p-sortIcon>
                                        </th>
                                        <th>Nombre del colaborador</th>
                                        <th pSortableColumn="ciudad_operacion" class="text-left">Ciudad Operación<p-sortIcon
                                            field="ciudad_operacion"></p-sortIcon>
                                        </th>
                                        <th>Nombre del hijo</th>
                                        <th class = 'text-center'>Fecha de nacimiento</th>
                                        <th class = 'text-center'>CURP</th>
                                        <th class = 'text-center'>Edad</th>
                                        <th class = 'text-center'>Sexo</th>
                                        <th>Observación</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-index>
                                    <tr>
                                        <td>
                                            <p class="font-weight-bold text-center">{{data.idx}}</p>
                                        </td>
                                        <td class="text-left texto_principal">{{data.nombre_chofer | uppercase }}</td>
                                        <td class = 'text-left'>{{data.ciudad_operacion }}</td>
                                        <td>{{data.des_nombre_apellido }}</td>
                                        <td class = 'text-center'>{{data.fec_nacimiento | date: 'dd/MM/yyyy'}}</td>
                                        <td class = 'text-center' style="color:blueviolet"><b>{{data.des_curp }}</b></td>
                                        <td class = 'text-center'><b>{{data.num_edad }}</b></td>
                                        <td class = 'text-center'>
                                            <span *ngIf="data.des_sexo=='M'" class="tag-mujer">Mujer</span>
                                            <span *ngIf="data.des_sexo=='H'" class="tag-hombre">Hombre</span>
                                        </td>
                                        <td>{{data.des_observacion }}</td>
                                        <td><p-tag *ngIf="data.clv_activo==1" severity="success" value="Activo"></p-tag>
                                            <p-tag *ngIf="data.clv_activo==0" severity="danger"
                                                value="Inactivo"></p-tag>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-around">
                                                <button class="btn btn-warning mr-2" pTooltip="Editar Informacion del hijo"
                                                    tooltipPosition="left" (click)="mostrarModalEditar(data);">
                                                    <i class="pi pi-pencil"></i>
                                                </button>
                                                <button class="btn btn-info" pTooltip="Estatus del registro"
                                                    (click)="confirmarCambiarEstatus(data.idx,data);">
                                                    <i class="pi pi-delete-left"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDialogo"
        [style]="{width: '70vw', height: '600px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
        (onHide)="cancelarModal()">
        <ng-template pTemplate="header">
            {{ texto_operacion }}
            <div *ngIf="clv_editar" style="color:black;">
                {{nombre_registro }}
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="mt-4">
                <form [formGroup]="dataForm" (ngSubmit)="guardarInformacion()">
                    <div class="row mt-4">
                        <div class="col-12">
                            <h4><strong>Datos del Colaborador</strong></h4>
                        </div>
                        <div class="form-group col-md-6">
                            <p-dropdown [options]="lstTodosColaboradores" optionLabel="nombre_completo" formControlName="cod_chofer"
                                autoWidth="false" [showClear]="false" [filter]="true" [filterBy]="'nombre_completo'" placeholder="Seleccione Colaborador"
                                [style]="{'width':'100%'}" optionValue="cod_chofer">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">
                            <h4><strong>Datos del Hijo</strong></h4>
                        </div>
                        <div class="form-group col-md-6">
                            <label><strong>Nombre del hijo</strong></label>
                            <input type="text" class="form-control" formControlName="des_nombre" placeholder="Nombre">
                        </div>
                        <div class="form-group col-md-6">
                            <label><strong>Apellido del hijo</strong></label>
                            <input type="text" class="form-control" formControlName="des_apellido" placeholder="Apellido">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="form-group col-md-6">
                            <label><strong>CURP</strong></label>
                            <input type="text" class="form-control" formControlName="des_curp" placeholder="CURP" maxlength="18" minlength="18">
                        </div>
                        <div class="form-group col-md-6">
                            <label><strong>Sexo</strong></label>
                            <p-dropdown [options]="sexoOptions" optionLabel="label" formControlName="des_sexo"
                                placeholder="Seleccione Sexo" [style]="{'width':'100%'}">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="form-group col-md-6">
                            <label><strong>Fecha de Nacimiento</strong></label>
                            <input type="date" class="form-control" formControlName="fec_nacimiento">
                        </div>
                        <div class="form-group col-md-6">
                            <label><strong>Observaciones</strong></label>
                            <textarea class="form-control" formControlName="des_observacion" placeholder="Observaciones"></textarea>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center mt-5">
                        <div class="col-2">
                            <button type="submit" class="btn btn-success form-control" [disabled]="!dataForm.valid">
                                <span class="text-white">Guardar</span>
                                <i class="pi pi-save text-white"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="cancelarModal();"></button>
        </ng-template>
    </p-dialog> 
        <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDialogoEditar"
        [style]="{width: '70vw', height: '600px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
        (onHide)="cancelarModal()">
        <ng-template pTemplate="header">
            {{ texto_operacion }}
            <div *ngIf="clv_editar" style="color:black;">
                {{nombre_registro }}
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="mt-4">
                <form [formGroup]="dataForm" (ngSubmit)="guardarInformacionEditar()">
                    <div class="row mt-4">
                        <div class="col-12">
                            <h4><strong>Datos del Colaborador</strong></h4>
                        </div>
                        <div class="form-group col-md-6">
                            <p-dropdown [options]="lstTodosColaboradores" optionLabel="nombre_completo" formControlName="cod_chofer"
                                autoWidth="false" [showClear]="false" [filter]="true" [filterBy]="'nombre_completo'" placeholder="Seleccione Colaborador"
                                [style]="{'width':'100%'}" optionValue="cod_chofer"> 
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">
                            <h4><strong>Datos del Hijo</strong></h4>
                        </div>
                        <div class="form-group col-md-6">
                            <label><strong>Nombre del hijo</strong></label>
                            <input type="text" class="form-control" formControlName="des_nombre" placeholder="Nombre" [value]="dataForm.controls['des_nombre'].value">
                        </div>
                        <div class="form-group col-md-6">
                            <label><strong>Apellido del hijo</strong></label>
                            <input type="text" class="form-control" formControlName="des_apellido" placeholder="Apellido" [value]="dataForm.controls['des_apellido'].value">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="form-group col-md-6">
                            <label><strong>CURP</strong></label>
                            <input type="text" class="form-control" formControlName="des_curp" placeholder="CURP" maxlength="18"  minlength="18" [value]="dataForm.controls['des_curp'].value">
                        </div>
                        <div class="form-group col-md-6">
                            <label><strong>Sexo</strong></label>
                            <p-dropdown [options]="sexoOptions" optionLabel="label" formControlName="des_sexo"
                                placeholder="Seleccione Sexo" [style]="{'width':'100%'}">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="form-group col-md-6">
                            <label><strong>Fecha de Nacimiento</strong></label>
                            <input type="date" class="form-control" formControlName="fec_nacimiento" [value]="dataForm.controls['fec_nacimiento'].value">
                        </div>
                        <div class="form-group col-md-6">
                            <label><strong>Observaciones</strong></label>
                            <textarea class="form-control" formControlName="des_observacion" placeholder="Observaciones" [value]="dataForm.controls['des_observacion'].value"></textarea>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center mt-5">
                        <div class="col-2">
                            <button type="submit" class="btn btn-success form-control" [disabled]="!dataForm.valid">
                                <span class="text-white">Guardar Editar</span>
                                <i class="pi pi-save text-white"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="cancelarModal();"></button>
        </ng-template>
    </p-dialog>   
    </div>
</div>
