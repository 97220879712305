import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IChoferFamilia } from '../models/choferfamilia';
import { IApoyoDiscapacidad } from '../models/apoyodiscapacidad';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HijosChoferService {
  private apiUrl: string ='';
  constructor(private _http: HttpClient) { 
    this.apiUrl = environment.sUrlNomina + 'ApoyoDiscapacidad';
  }

  public listarFamiliaChofer(): Observable<any> {
    return this._http.get(this.apiUrl + '/getFamiliaChofer');
  }

   public saveFamiliaChofer(data: IChoferFamilia ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this._http.post<any>(this.apiUrl+'/saveFamiliaChofer',body,{'headers':headers});
   }

   public editarFamiliaChofer(data: IChoferFamilia ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this._http.put<any>(this.apiUrl+'/updateFamiliaChofer',body,{'headers':headers});
   }

   public estatusFamiliaChofer(data: IChoferFamilia ):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body = JSON.stringify(data); 
    return this._http.put<any>(this.apiUrl+'/activoFamiliaChofer',body,{'headers':headers});
   }

   public getApoyoDiscapacidad(num_anio: number,id_semana:number,id_ciudad_hm: number): Observable<IApoyoDiscapacidad[]> {
     const valores = {
       params: new HttpParams().
          set('num_anio',num_anio).
          set('id_semana',id_semana).
          set('id_ciudad_hm',id_ciudad_hm)
   
     }
     return this._http
         .get<IApoyoDiscapacidad[]>(this.apiUrl +'/getListaColaboradores',valores)
         .pipe(retry(1), catchError(this.handleError));
   
    }

    handleError(error: any) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        errorMessage = `Error: ${error.error.message}`;
      } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.error(errorMessage);
      return throwError(errorMessage);
    }

    public postApoyoDiscapacidad(data: any):Observable<any>{
      const headers = { 'content-type': 'application/json'}
      const body = JSON.stringify(data);
      return this._http.post<any>(this.apiUrl + '/saveApoyoDiscapacidad/',body,{'headers':headers});
     }

    public deleteApoyoDiscapacidad(data: any):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this._http.post<any>(this.apiUrl + '/deleteApoyoDiscapacidad/',body,{'headers':headers});
    }
    
 getrptapoyodisc(num_anio: number,id_ciudad_hm: number): Observable<any[]> {
  const valores = {
    params: new HttpParams().
       set('num_anio',num_anio).
       set('id_ciudad_hm',id_ciudad_hm)

  }
  return this._http
      .get<any[]>(this.apiUrl +'/rptapoyodisc',valores)
      .pipe(retry(1), catchError(this.handleError));

 }
}