import { CajaahorroService } from './../services/cajaahorro.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ICajaAhorro } from '../models/caja_ahorro';
import { Table } from 'primeng/table';
import { AuthService } from '../services/auth.service';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import ICredencialUsuario from '../models/credencial';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CiudadhmService } from '../services/ciudadhm.service';
import { ICiudadHM } from '../models/ciudadhm';
import { ICajaAhorroEnviar } from '../models/caja_ahorro_enviar';
import { IColaboradoresActivos } from '../models/colaboradores_activos';
import { Iahorro } from '../models/listaahorro';
import { SemanaService } from '../services/semana.service';
import { ISemanasHappyMile } from '../models/semanas';
import { IEstatus } from '../models/estatus';
import { ICiudadPrincipalHM } from '../models/ciudadprincipal';
import { BonoaguinaldoService } from '../services/bonoaguinaldo.service';
import * as saveAs from 'file-saver';
import { IMes } from '../models/mes';
import { CajaahorroanualService } from '../services/cajaahorroanual.service';
import { ICajaAhorroAnual } from '../models/cajaahorroanual';

@Component({
  selector: 'app-cajaahorroanual',
  templateUrl: './cajaahorroanual.component.html',
  styleUrls: ['./cajaahorroanual.component.scss']
})
export class CajaahorroanualComponent implements OnInit {
  @ViewChild('dt1') dt!: Table;
  user: ICredencialUsuario = {};


  lstColaboradores: ICajaAhorroAnual[] = [];
  lstahorro: Iahorro[] = [];
  lstTodosColaboradores: IColaboradoresActivos[] = [];
  lstSemanas: ISemanasHappyMile[] = [];
  lstCiudad: ICiudadHM[] = [];
  lstCiudadPrincipal: ICiudadPrincipalHM[] = [];
  lstCiudadPrincipal2: ICiudadPrincipalHM[] = [];


  loading: boolean = false;
  fecha_dia: any = null;
  anio: any;
  num_anio: number = 0;
  num_anio_rpt: number = 0;
  dspDialogo: boolean = false;
  texto_operacion: string = '';
  nombre_registro: string = '';

  imp_total: number = 0;
  clv_pagado: number = 0;
  clv_his: number = 0;
  clv_operacion: number = 0;
  nombre_completo: string = '';
  cod_chofer: number = 0;
  dsphistorico: boolean = false;
  fecha_final_dia!: Date;
  imp_total_general_propeccion: number = 0;
  imp_total_general: number = 0;
  imp_interes: number = 0;
  fecha_del_dia: any = null;
  fecha_minus3 !: Date;
  fecha_dia_real: any = null;
  loading_reporte1: boolean = false
  ButtonExportExcel: boolean = false
  id_ciudad_principal_rpt: number = 0
  dbsReportes: boolean = false;
  dsppagoanual: boolean = false;

  meses: IMes[] = [
    { num_mes: 0, nombre: "TODOS LOS MESES" },
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];

  selectedMesReporte1: IMes = {
    num_mes: -1,
    nombre: ""
  }
  anioReporte1: any;
  //id_ciudad_principal:number =0;

  years: number[] = [];

  id_ciudad_principal: ICiudadPrincipalHM = {
    id: 0,
    nombre: '',
    clave_estado: ''
  };

  dataForm: FormGroup = new FormGroup({
    id_caja_ahorro: new FormControl(null),
    cod_chofer: new FormControl(null, [Validators.required]),
    id_ciudad_hm: new FormControl({ value: null, disabled: true }, [Validators.required]),
    imp_ahorro: new FormControl(null, [Validators.required]),
    id_ciudad_principal: new FormControl(),
    //  num_anio: new FormControl(0)
  });

  selectedSemana: ISemanasHappyMile = {
    idx: 0,
    id_semana: 0,
    nom_semana: '',
    anio: 0,
    fec_inicio: '',
    fec_fin: '',
    clv_activo: 0
  };

  lstEstatus: IEstatus[] = [{
    id: 1,
    nombre: 'ACTIVO'
  },
  {
    id: 0,
    nombre: 'INACTIVO'
  }];

  constructor(private auth: AuthService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private cajaahorroService: CajaahorroService,
    private semanaService: SemanaService,
    private bonoaguinaldoService: BonoaguinaldoService,
    private ciudadhmService: CiudadhmService,
    private cajaahorroanualService: CajaahorroanualService) {
    this.cargarInformacionUsuario();
    this.initializeYears();
    this.num_anio = new Date().getFullYear();
    this.num_anio_rpt = new Date().getFullYear();

  }

  initializeYears(): void {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= currentYear - 5; year--) {
      this.years.push(year);
    }
  }




  ngOnInit(): void {
    // this.cargarListadodeCiudades();
    // this.cargarListadodeColaboradores();
    this.inicializarFechas();
    this.cargarListadoDeSemanasAnio();
    this.cargaInicial();
  }

  cargarListadoDeSemanasAnio() {
    this.lstSemanas = [];
    this.semanaService.listarSemanas(this.anio).subscribe((resp) => {
      this.lstSemanas = resp;
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
      });
  }
  getTargetDescription(ruta: ISemanasHappyMile) {
    let fec_inicio = new Date(ruta.fec_inicio);
    let fec_fin = new Date(ruta.fec_fin);
    return this.formatearFechas(fec_inicio) + ' | ' + this.formatearFechas(fec_fin);
  }
  formatearFechas(fecha: Date): string {
    let fecha_Cast = '';
    var getYear = new Date(fecha).toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date(fecha).toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date(fecha).toLocaleDateString('es-MX', { day: '2-digit' });
    fecha_Cast = getDay + '/' + getMonth + '/' + getYear;
    return fecha_Cast;
  }

  // cargarListadodeCiudades() {
  //   this.ciudadhmService.getListadoCiudades().subscribe((resp) => {
  //     this.lstCiudad = resp;
  //   }, (error) => {
  //     this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Listar Ciudades>', detail: 'Contacte al Administrador del Sitio' });
  //   });
  // }


  cargaInicial() {

    this.loading = true;
    this.bonoaguinaldoService.getListadoCiudadesPrincipales().subscribe((resp) => {
      this.lstCiudadPrincipal = resp.slice();
    //  this.lstCiudad = resp.slice(); // Crear una copia independiente
      this.lstCiudadPrincipal2 = resp.slice();
      this.lstCiudadPrincipal2.push({ id: 0, nombre: 'TODAS LAS CIUDADES', clave_estado: '' })
      setTimeout(() => {
        this.loading = false;

      }, 1000);
    },
      (error) => {
        this.loading = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Ciudades');
      });
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }


  cargarInformacion(num_anio: any) {
    this.imp_total_general = 0;
    this.imp_total_general_propeccion = 0;
    this.imp_interes = 0;
    let anio_cast: any = 0;
    if (num_anio instanceof Date) {
      // will execute
      anio_cast = num_anio.getFullYear();
    } else {
      anio_cast = num_anio;
    }
    if (anio_cast == null || typeof anio_cast == 'undefined') {
      let mensaje = 'Seleccione un Año para mostrar Información';
      this.mostrarDialogoInformativoSinInformacion(mensaje);
    } else {
      //this.anio = anio_cast;
      this.loading = true;
      this.cajaahorroanualService.getCajaAhorroanual(anio_cast).subscribe((resp) => {
        this.lstColaboradores = resp;


        if (this.lstColaboradores.length === 0) {
          this.loading = false;
          this.mostrarDialogoInformativoSincontacteadmi('<b>No Existe</b> Registros para la Fecha seleccionada');
        }
        else{
          //this.loading = false;

          this.imp_total_general = 0;
          this.imp_total_general_propeccion = 0;
          this.imp_interes = 0;
          // Iterar sobre la lista y sumar los valores de los campos
          this.lstColaboradores.forEach((resp) => {
            this.imp_total_general += resp.imp_ahorrado;
            this.imp_total_general_propeccion += resp.imp_prospeccion
            this.imp_interes += resp.imp_interes
            setTimeout(() => {
              this.loading = false;
            }, 800);
          });
        }

      },
        (error) => {
          this.loading = false;
          this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Cargar Caja de Ahorro>', detail: 'Contacte al Administrador del Sitio' });
        });
    }
  }


  cancelarModalanual() {
    this.dsppagoanual = false;
    this.texto_operacion = '';
    this.dataForm.controls['id_ciudad_principal'].setValue(0);
    this.num_anio = 0;
    this.num_anio = new Date().getFullYear();
  }

  generarpagoanual() {
    this.dsppagoanual = true;
    this.texto_operacion = 'Caja de ahorro anual';
  }


  pagoAnual() {
    if (this.id_ciudad_principal.id == 0) {
      this.mostrarDialogoInformativoSincontacteadmi('<b>Informativo</b> Debe seleccionar un año y ciudad para poder paagr la caja de ahorro anual');
    } else {
      this.confirmationService.confirm({
        message: 'Esta seguro que desea pagar la caja de ahorro <b>ANUAL </b> de <b>TODOS</b> los colaboradores  de la ciudad <b>' + this.id_ciudad_principal.nombre + '</b> a los cuales se les otorgara el porcentaje correspondiente ?',
        header: 'Confirmación de ',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.cajaahorroService.pagoanual(this.anio, this.user.cod == undefined ? '0999' : this.user.cod, this.id_ciudad_principal.id).subscribe((resp) => {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: resp.messageDetails });
            this.cargarInformacion(this.anio);
            this.dsppagoanual = false;
          })
        },
        reject: () => {
          this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
        }
      });
    }
  }

  cancelarModal() {
    this.dspDialogo = false;
    this.texto_operacion = '';
    this.nombre_registro = '';
    this.dataForm.reset();
    this.clv_operacion = 0;
    this.dataForm.get('cod_chofer')!.enable();

    this.clv_his = 0;
  }

  cancelarVentanaModal() {
    this.dsphistorico = false;
    this.nombre_completo = '';
    this.cod_chofer = 0;

  }


  abrirModalReportes() {
    this.dbsReportes = true;
    this.inicializarFechasFormateadas();
  }
  cancelarModalReportes() {

    this.dbsReportes = false;
    // Inicio Reporte 1
    this.selectedMesReporte1 = {
      num_mes: 0,
      nombre: ''
    }
    this.anioReporte1 = null;

    this.num_anio_rpt = new Date().getFullYear();

  }


  // metodo para exportar a excel
  exportarExcel(num_anio_rpt: number, id_ciudad_principal: number) {
    // this.num_anio_rpt = this.fecha_filtrada;
    this.loading_reporte1 = true;
    if (this.num_anio_rpt == null || this.id_ciudad_principal_rpt == null) {
      this.mostrarDialogoInformativo('<b>Aviso</b> Es necesario seleccionar <b>Fecha</b> de Operación a Filtrar');
    } else {
      this.cajaahorroanualService.rptCaaanual(this.num_anio_rpt, this.id_ciudad_principal_rpt)
        .subscribe((resp: ICajaAhorro[]) => {
          if (resp.length === 0 || typeof resp === 'undefined') {
            this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> No existe informacion del Catalogo para exportar(Excel)');
            this.loading_reporte1 = false;
          } else {
            import("xlsx").then(xlsx => {
              const worksheet = xlsx.utils.json_to_sheet(resp);
              const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
              const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array"
              });

              this.saveAsExcelFile(excelBuffer, "ReporteCajaAhorro" + '_' + num_anio_rpt);
            });
            setTimeout(() => {
              this.ButtonExportExcel = false;
              this.loading_reporte1 = false;
            }, 1000);
          }
        },
          (error) => {
            this.ButtonExportExcel = false;
            this.loading_reporte1 = false;

            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en emitir el reporte de Estatus de Facturación.' });
          });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    //var FileSaver = require('file-saver');
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_exportar_" + dateFormat + EXCEL_EXTENSION);
  }


  inicializarFechasFormateadas() {
    //hacer globales
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
    this.fecha_del_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha que no se modifica
    this.fecha_final_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha para restar los 3 dias
    let minus3days = new Date(this.fecha_final_dia);
    this.fecha_minus3 = new Date(minus3days.setDate(new Date(minus3days).getDate() - 4));
    //Fecha del dia real, no se mueve
    this.fecha_dia_real = dateFormat;
    //Reportes
    let num_mes: number = +getMonth;
    this.meses.map((mes) => {
      if (mes.num_mes == num_mes) {
        this.selectedMesReporte1.nombre = mes.nombre;
        this.selectedMesReporte1.num_mes = mes.num_mes;
      }
    });
    this.anioReporte1 = getYear;
  }




  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
    this.messageService.clear();
  }


  mostrardesgloce(data: Iahorro) {
    let imp = this.lstColaboradores.find(chofer => chofer.cod_chofer === data.cod_chofer);
    if (imp) {
      this.imp_total = imp.imp_ahorrado;
    } else {
      this.imp_total = 0; // Asigna un valor por defecto si no se encuentra el colaborador
    }
    this.nombre_completo = data.nombre_completo;
    this.cod_chofer = data.cod_chofer;
    this.dsphistorico = true;

    this.cajaahorroService.gethisahorro(data.cod_chofer).subscribe(
      (resp) => {
        this.lstahorro = resp;

      },
      (error) => {
        console.error('Error al obtener historial de ahorro:', error);
        // Maneja el error según sea necesario
      }
    );
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  cancelarMensajeConfirmacion() {
    this.messageService.clear('confirm');
    this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });

  }

  confirmarMensaje() {
    this.messageService.clear('confirm');
    this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
  }



  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }
  mostrarDialogoInformativoSincontacteadmi(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
    this.anio = getYear;
  }
}
