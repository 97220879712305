import { Component, OnInit, ViewChild } from '@angular/core';
import { IDiasFestivos } from '../models/diasfestivos';
import { IHijosChofer } from '../models/hijoschofer';
import { IChoferFamilia } from '../models/choferfamilia';
import { Table } from 'primeng/table';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DiasService } from '../services/dias.service';
import { HijosChoferService } from '../services/hijoschofer.service';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { IErrorCode } from '../models/errorcode';
import ICredencialUsuario from '../models/credencial';
import { AuthService } from '../services/auth.service';
import { IColaboradoresActivos } from '../models/colaboradores_activos';
import { CajaahorroService } from './../services/cajaahorro.service';

@Component({
  selector: 'app-hijoschofer',
  templateUrl: './hijoschofer.component.html',
  styleUrls: ['./hijoschofer.component.scss']
})
export class HijoschoferComponent implements OnInit {
  @ViewChild('dt1') dt!: Table;
  user: ICredencialUsuario = {};
  lstTodosColaboradores: IColaboradoresActivos[] = [];
  dspDialogo:boolean=false;
  isDisabled: boolean = false;
  dspDialogoEditar:boolean=false;
  texto_operacion:string='';
  sexoOptions = [
      { label: 'Hombre', value: 'H' },
      { label: 'Mujer', value: 'M' }
    ];
  clv_editar:boolean=false;
  nombre_registro:string='';
  listsHijosChofer:IHijosChofer[]=[];
  loading:boolean=false;
  dataForm: FormGroup = new FormGroup({
    cod_chofer: new FormControl(null, [Validators.required]),
    fec_nacimiento: new FormControl('', [Validators.required]),
    des_nombre: new FormControl('', [Validators.required]), 
    des_apellido: new FormControl('', [Validators.required]),
    des_curp: new FormControl('', [Validators.required]),
    des_sexo: new FormControl('', [Validators.required]),
    des_observacion: new FormControl(''),
    idx: new FormControl(null), 
    num_accion: new FormControl(1) 
  });
  
  clv_operacion:number=0;
  error_captura!: IErrorCode;
  fecha_dia:any=null;
  fecha_dia_festivo:any=null;
  constructor(private diasfestivosService: DiasService,
    private confirmationService: ConfirmationService, 
    private messageService: MessageService, 
    private hijoschoferService: HijosChoferService,
    private auth: AuthService,
    private cajaahorroService: CajaahorroService
  ){

  }
  ngOnInit(): void {
    this.cargarListado();
    this.cargarListadodeColaboradores();
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  cargarListado() {
    this.loading = true;
    this.hijoschoferService.listarFamiliaChofer().subscribe(
      (resp) => {
        this.listsHijosChofer = resp;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
      }
    );
  }

  mostrarModalAgregar(){
    this.clv_operacion=1;
    this.texto_operacion='Nuevo Registro';
    this.dspDialogo=true;
    this.dataForm.controls['cod_chofer'].enable(); 
  }

  guardarInformacion() {
    this.cargarInformacionUsuario(); 
    let data: IChoferFamilia = {
        idx: 0,
        num_accion: 1, 
        cod_chofer: this.dataForm.get('cod_chofer')?.value,  
        des_nombre: this.dataForm.get('des_nombre')?.value, 
        des_apellido: this.dataForm.get('des_apellido')?.value, 
        des_curp: this.dataForm.get('des_curp')?.value,  
        fec_nacimiento: this.dataForm.get('fec_nacimiento')?.value, 
        des_sexo: this.dataForm.get('des_sexo')?.value?.value,
        des_observacion: this.dataForm.get('des_observacion')?.value || '',
        cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod 
    };
    setTimeout(() => {
        this.loading = true;
        this.hijoschoferService.saveFamiliaChofer(data).subscribe(
            (resp) => {
                // Si la respuesta es correcta, seguimos el flujo normal
                if (resp.clv_estatus === 0) {
                    // Si clv_estatus es 0 (error de CURP duplicada), manejamos el error
                    this.messageService.add({
                        key: 'msj',
                        severity: 'error',
                        summary: 'Error en el Registro',
                        detail: resp.messageDetails || 'Error desconocido'
                    });
                    this.loading = false;
                } else {
                    // Si no hay error, mostramos el mensaje de éxito
                    this.cancelarModal();
                    this.messageService.add({ 
                        key: 'msj', 
                        severity: 'success', 
                        summary: 'Registro Exitoso', 
                        detail: 'La información se registró correctamente' 
                    });
                    this.loading = false;
                    this.cargarListado();
                }
            },
            (error) => {
                // Aquí manejamos el error general si no es un error de CURP duplicada
                if (error.status === 409 && error.error) {
                    this.messageService.add({
                        key: 'msj',
                        severity: 'error',
                        summary: 'Error en el Registro',
                        detail: error.error.messageDetails || 'Error desconocido'
                    });
                    this.loading = false;
                } else {
                    // Si no es un error de CURP duplicada, mostramos un mensaje genérico
                    this.messageService.add({
                        key: 'msj',
                        severity: 'error',
                        summary: 'Error en <Agregar>',
                        detail: 'Contacte al Administrador del Sitio'
                    });
                    this.loading = false;
                }
            }
        );
    }, 1000);  
}

guardarInformacionEditar() {
  this.cargarInformacionUsuario(); 
  let data: IChoferFamilia = {
      idx: this.dataForm.get('idx')?.value, 
      num_accion: 2,  
      cod_chofer: this.dataForm.get('cod_chofer')?.value,  
      des_nombre: this.dataForm.get('des_nombre')?.value, 
      des_apellido: this.dataForm.get('des_apellido')?.value, 
      des_curp: this.dataForm.get('des_curp')?.value,  
      fec_nacimiento: this.dataForm.get('fec_nacimiento')?.value, 
      des_sexo: this.dataForm.get('des_sexo')?.value?.value,
      des_observacion: this.dataForm.get('des_observacion')?.value || '',
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod 
  };
  setTimeout(() => {
      this.loading = true;
      this.hijoschoferService.editarFamiliaChofer(data).subscribe(
          (resp) => {
              if (resp.clv_estatus === 0) {
                  // Si clv_estatus es 0 (error de CURP duplicada), manejamos el error
                  this.messageService.add({
                      key: 'msj',
                      severity: 'error',
                      summary: 'Error en el Registro',
                      detail: resp.messageDetails || 'Error desconocido'
                  });
                  this.loading = false;
              } else {
                  // Si no hay error, mostramos el mensaje de éxito
                  this.cancelarModal();
                  this.messageService.add({ 
                      key: 'msj', 
                      severity: 'success', 
                      summary: 'Edición Exitosa', 
                      detail: 'La información se actualizó correctamente' 
                  });
                  this.loading = false;
                  this.cargarListado();
              }
          },
          (error) => {
              if (error.status === 409 && error.error) {
                  this.messageService.add({
                      key: 'msj',
                      severity: 'error',
                      summary: 'Error en el Registro',
                      detail: error.error.messageDetails || 'Error desconocido'
                  });
                  this.loading = false;
              } else {
                  this.messageService.add({
                      key: 'msj',
                      severity: 'error',
                      summary: 'Error en <Editar>',
                      detail: 'Contacte al Administrador del Sitio'
                  });
                  this.loading = false;
              }
          }
      );
  }, 1000); 
}

  cancelarModal(){
    this.clv_operacion=0;
    this.texto_operacion='';
    this.dspDialogo=false;
    this.dspDialogoEditar=false;
    this.dataForm.reset();
    this.fecha_dia_festivo = '';
    this.fecha_dia ='';
  }

  mostrarModalEditar(data: IHijosChofer) {
    this.clv_operacion = 2;  
    this.texto_operacion = 'Editar Registro';
  
    // Separar el nombre y el apellido
    const nombreApellido = data.des_nombre_apellido.split(' '); 
    const nombre = nombreApellido[0]; 
    const apellido = nombreApellido.slice(1).join(' '); 
  
    // Convertir la fecha de nacimiento a formato 'YYYY-MM-DD'
    let fechaFormateada = new Date(data.fec_nacimiento).toISOString().substring(0, 10);
  
    // Asignar los valores al formulario
    this.dataForm.controls['idx'].setValue(data.idx);
    this.dataForm.controls['cod_chofer'].setValue(data.cod_chofer);
    this.dataForm.controls['des_nombre'].setValue(nombre);
    this.dataForm.controls['des_apellido'].setValue(apellido);
    this.dataForm.controls['des_curp'].setValue(data.des_curp);
    this.dataForm.controls['fec_nacimiento'].setValue(fechaFormateada);
    this.dataForm.controls['des_sexo'].setValue(this.sexoOptions.find(option => option.value === data.des_sexo));
    this.dataForm.controls['des_observacion'].setValue(data.des_observacion || ''); 
  
    this.dataForm.controls['cod_chofer'].disable(); 
  
    // Mostrar el modal de edición
    this.dspDialogoEditar = true;
  }
  
  
  

  inicializarFechas():string{
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    return dateFormat;
  }

  confirmarCambiarEstatus(id: number, data: IHijosChofer) {
    let clv_estatus_original: number = data.clv_activo;
    let texto_desactivar: string = data.clv_activo == 1 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = data.clv_activo == 1 ? 'Desactivar' : 'Activar';
  
    this.confirmationService.confirm({
      message: 'Está seguro que desea <b>' + texto_desactivar + '</b> el registro del hijo <b>'+ data.des_nombre_apellido + '</b> del colaborador <b>' + data.nombre_chofer + '</b>?',
      header: 'Confirmación de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        data.clv_activo = data.clv_activo == 1 ? 0 : 1;
  
        // Crear el objeto IChoferFamilia con los valores necesarios
        const payload: IChoferFamilia = {
          idx: data.idx,           
          num_accion: 3,         
          cod_chofer: 0,           
          des_nombre: ' ',          
          des_apellido: ' ',        
          fec_nacimiento: new Date('1900-01-01'), 
          des_curp: ' ',           
          des_sexo: ' ',           
          des_observacion: ' ',     
          cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod 
        };
  
        // Llamar a la función para guardar la información en la API
        this.guardarInformacionDesactivar(payload);
        this.loading = true;
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
          case ConfirmEventType.CANCEL:
            data.clv_activo = clv_estatus_original;
            break;
        }
      }
    });
  }
  
  cargarListadodeColaboradores() {
    this.cajaahorroService.cargarInformacionColaboradoresActivos().subscribe((resp) => {
      this.lstTodosColaboradores = resp;
    }, (error) => {
      this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en <Listar Colaboradores>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  guardarInformacionDesactivar(data: IChoferFamilia) {
    setTimeout(() => {
        this.hijoschoferService.estatusFamiliaChofer(data).subscribe(
            (resp) => {
                this.cargarListado();
                this.messageService.add({
                    key: 'msj',
                    severity: 'success',
                    summary: 'Registro Exitoso',
                    detail: 'La información se actualizó correctamente'
                });
                this.loading = false;
            },
            (error) => {
                this.messageService.add({
                    key: 'msj',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Contacte al Administrador del Sitio'
                });
                this.loading = false;
            }
        );
    }, 1000);  
}
 
  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }
}