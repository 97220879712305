import { Component } from '@angular/core';
import { IResultadoGeneral } from './../models/resultadogeneral';
import { ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { saveAs } from 'file-saver';
import ICredencialUsuario from '../models/credencial';
import { ICiudad } from '../models/ciudad';
import { InombreCompletoChofer } from '../models/nombreCompletoChofer';
import { IMes } from '../models/mes';
import { IrptEstatusForaneo } from '../models/rptEstatusForaneo';
import { IEstatusForaneo } from '../models/estatusforaneo';
import { BonoaguinaldoService } from '../services/bonoaguinaldo.service';
import { ISemanasHappyMile } from '../models/semanas';
import { SemanaService } from '../services/semana.service';
import { InfonavitService } from '../services/infonavit.service';
import { AuthService } from '../services/auth.service';
import { ICiudadHM } from '../models/ciudadhm';
import { CiudadhmService } from './../services/ciudadhm.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BonoRecomendacionService } from '../services/bonorecomendacion.service';
import { IPostInfonavit } from '../models/postinfonavit';
import { IInfonavit } from '../models/infonavit';

import { INomina } from '../models/nomina';
import { IJsonDia } from '../models/json_dia';
import { IhisInfonavit } from '../models/hisinfonavit';
import { ICorSemInfonavit } from '../models/postcortseminfonavit';
import { IEstatus } from '../models/estatus';

@Component({
  selector: 'app-infonavit',
  templateUrl: './infonavit.component.html',
  styleUrls: ['./infonavit.component.scss']
})
export class InfonavitComponent {

  @ViewChild('dt1') dt!: Table;
  lstCiudad: ICiudadHM[] = [];
  lstCiudad2: ICiudadHM[] = [];
  lstChoferes: InombreCompletoChofer[] = [];
  lstchofCopy: InombreCompletoChofer[] = [];
  lstEstatusForaneo: IEstatusForaneo[] = [];
  lstInfonavit: IInfonavit [] = [];
  lstSemanas: ISemanasHappyMile[] = [];
  lstNominaPorCiudad: INomina[] = [];
  lstJsonPorDia: IJsonDia[]=[];
  lsthisInfonavit:IhisInfonavit[]=[];

  mssgListaCiudades: string = '';
  selectedSemana: ISemanasHappyMile = {
    idx: 0,
    id_semana: 0,
    nom_semana: '',
    anio: 0,
    fec_inicio: '',
    fec_fin: '',
    clv_activo: 0
  };
  selectedSemanaReporte: ISemanasHappyMile = {
    idx: 0,
    id_semana: 0,
    nom_semana: '',
    anio: 0,
    fec_inicio: '',
    fec_fin: '',
    clv_activo: 0
  };

  lstrptDescansoFaltas: IrptEstatusForaneo[] = [];
  selectedCiudad: ICiudad = {
    id: 0,
    nombre: '',
    clave_Estado: '',
    id_ciudad_principal: 0
  };

  //num_anio: number= 0;
  years: number[] = [];
  loading: boolean = false;
  anio_filtro:any;

  //variables usadas para la fecha, fueron casteadas ya que presentaban errorees
  dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  fecha_dia: any = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
  anio: any = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
  fecha_fin: any = null;
  id_ciudad_hm: number = 0;
  id_ciudad_principal: number = 0;
  texto_operacion: string = '';
  num_operacion: number = 0;
  clv_operacion: number = 0;
  habilitar_agregar: boolean = false;
  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };
  habilitaragregar :boolean=false;
  dspDeduccion:boolean = false;
  dsblstCiudades: boolean = true;
  dsblstColaboradores: boolean = true;
  dsblstCiudadesAdscritas: boolean = true;
  // variables de reportes--------------------------
  dbsReportes: boolean = false;
  fecha_fin_filtro: any = null;
  ButtonExportExcel: boolean = false;
  //Fecha de Dias Atras
  fecha_del_dia: any = null;
  mostrar_texto_fechas: number = 0;
  fecha_final_dia!: Date;
  //Fecha para restar 3 dias
  fecha_minus3 !: Date;
  fecha_dia_real: any = null;
  selectedMesReporte1: IMes = {
    num_mes: -1,
    nombre: ""
  }
  lstEstatus: IEstatus[] = [{
    id: 1,
    nombre: 'ACTIVO'
  },
  {
    id: 2,
    nombre: 'INACTIVO'
  }];

  imp_total_general:number=0;
  clv_his:number=0;
  clv_activo: number= 0;
  nombre_completo:string='';
  cod_chofer:number=0;
  imp_total:number =0;
  dsphistorico:boolean=false;
  id_ciudad_hm_rpt: number=0;
  deshabilitarbtnexportar:boolean=false;
  num_anio:number=0;
  nombre_registro:string='';
  dspcortesemanal:boolean =false

  anioReporte1: any;
  loading_reporte1: boolean = false;
  meses: IMes[] = [
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];

    reactiveforminfonavit: FormGroup = new FormGroup({
      idx: new FormControl(0),
      nom_chofer: new FormControl(''),
      cod_chofer: new FormControl(0, [Validators.min(1),Validators.required]),
      id_ciudad_hm: new FormControl(0),
      num_anio: new FormControl(new Date().getFullYear()),
      //id_semana: new FormControl(0, [Validators.min(1),Validators.required]),
      selectedSemana : new FormControl([],[Validators.min(1),Validators.required]),
      nombreCiudad: new FormControl(''),
      fec_inicio : new FormControl(''),
      fec_fin : new FormControl(''),
      imp_deduccion: new FormControl(0,[Validators.min(1),Validators.required]),
      clv_operacion: new FormControl(0),
    });

  //Permisos
  user: ICredencialUsuario = {};
  constructor(

    private bonoaguinaldoService: BonoaguinaldoService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private semanaService: SemanaService,
    private InfonavitService:InfonavitService,
    private auth: AuthService,
    private CiudadhmService: CiudadhmService,
    private bonoRecomendacionService: BonoRecomendacionService
    ) {

    this.initializeYears();
   // this.num_anio = new Date().getFullYear();
    this.cargarInformacionUsuario();

    this.reactiveforminfonavit.controls['selectedSemana'].valueChanges.subscribe(data => {
      if (data==null) {
        //this.resetearFormularios();
        this.reactiveforminfonavit.controls['fec_inicio'].setValue('');
        this.reactiveforminfonavit.controls['fec_fin'].setValue('');
      } else {
        this.reactiveforminfonavit.controls['fec_inicio'].setValue(data.fec_inicio);
        this.reactiveforminfonavit.controls['fec_fin'].setValue(data.fec_fin);
      }
    });
  }

  ngOnInit(): void {
    this.cargaInicial();
    this.cargarListadoDeSemanasAnio();
    this.cargaListaChoferes();
    this.inicializarFechas();
    this.num_anio = new Date().getFullYear();
  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  initializeYears(): void {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= currentYear - 5; year--) {
      this.years.push(year);
    }
  }

  cargarListadoDeSemanasAnio_cast(num_anio:any) {
    this.imp_total_general = 0;
    this.loading=true;
    let anio_cast: any = 0;
    if (num_anio instanceof Date) {
      // will execute
      anio_cast = num_anio.getFullYear();
    } else {
      anio_cast = num_anio;
    }
    this.lstSemanas = [];
    this.semanaService.listarSemanas(anio_cast).subscribe((resp) => {
      this.lstSemanas = resp;
      this.loading=false;
      this.lstNominaPorCiudad = [];
      this.lstJsonPorDia=[];
      setTimeout(() => {
      },
        300);
    },
      (error) => {
        this.loading=false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error al cargar Semanas HM</b>. Contacte al Administrador.');
      });
  }




  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.anio = getYear;
    this.anio_filtro = getYear;
  }



// metodos para eñ filtro para fechas
  getTargetDescription(ruta: ISemanasHappyMile) {
    let fec_inicio = new Date(ruta.fec_inicio);
    let fec_fin = new Date(ruta.fec_fin);
    return this.formatearFechas(fec_inicio) + ' | ' + this.formatearFechas(fec_fin);
  }


  formatearFechas(fecha: Date): string {
    let fecha_Cast = '';
    var getYear = new Date(fecha).toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date(fecha).toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date(fecha).toLocaleDateString('es-MX', { day: '2-digit' });
    fecha_Cast = getDay + '/' + getMonth + '/' + getYear;
    return fecha_Cast;
  }



  cargarListadoDeSemanasAnio() {
    this.lstSemanas = [];
    this.semanaService.listarSemanas(this.anio).subscribe((resp) => {
      this.lstSemanas = resp;
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
      });
  }



  // -----------filtra y carga la informacion ----
  Filtrar() {
    this.imp_total_general = 0;
    this.loading = true;
    if (this.id_ciudad_hm == null || typeof this.id_ciudad_hm === 'undefined' ) {
      this.mostrarDialogoInformativoSincontacteadmi('<b>Informativo</b> Debe seleccionar una ciudad para poder filtrar');
      this.loading = false;
    } else   {
      this.habilitaragregar= true;
      this.InfonavitService.getListadodeducciones( this.id_ciudad_hm, this.user.cod == undefined ? '0999' : this.user.cod ).subscribe(
        (resp) => {
          this.lstInfonavit = resp;
          if (this.lstInfonavit.length === 0) {
            this.loading = false;
            this.mostrarDialogoInformativoSincontacteadmi('<b>No Existe</b> Registros para la Fecha seleccionada');
          }
          else {

              this.imp_total_general = 0;
              // Iterar sobre la lista y sumar los valores de los campos
              this.lstInfonavit.forEach((item) => {
                this.imp_total_general += item.imp_deduccion;
              });
            setTimeout(() => {
              this.loading = false;
            }, 800);
          }
        },
        (error) => {
          this.mostrarDialogoInformativoSincontacteadmi('<b>ERROR</b> en Cargar Movimientos ');
          this.loading = false;
        }
      );
    }
  }


  corteSemanal(){
    this.dspcortesemanal =true
    this.nombre_registro= 'Corte semanal de infonavit'
  }


  cancelarcorte(){
    this.dspcortesemanal =false
    this.num_anio = new Date().getFullYear();
    this.nombre_registro='';
    this.id_ciudad_hm=0;
    this.selectedSemana = {
      idx: 0,
      id_semana: 0,
      nom_semana: '',
      anio: 0,
      fec_inicio: '',
      fec_fin: '',
      clv_activo: 0
    };
  }



  generarcorte() {
    let data_enviar: ICorSemInfonavit = {
      id_ciudad_hm: this.id_ciudad_hm,
      id_semana:this.selectedSemana.id_semana,
      clv_his:this.clv_his,
      num_anio:this.selectedSemana.anio,
      cod_usuario:this.user.cod == undefined ? '0999' : this.user.cod
    }
    if (!this.selectedSemana || !this.selectedSemana.id_semana) {
      this.mostrarDialogoInformativoSincontacteadmi('<b>Informativo</b> Debe seleccionar una semana y ciudad para poder bajar la caja de ahorro');
    } else {
      this.confirmationService.confirm({
        message: '¿Está seguro de generar el corte de deducciones de <b>INFONAVIT</b> de la semana <b>' + this.selectedSemana.id_semana + '</b> de todos los colaboradores <b>Activos</b>?',
        header: 'Confirmación de edición de estatus',
        icon: 'pi pi-info-circle',
        accept: () => {
          const codUsuario = this.user.cod == undefined ? '0999' : this.user.cod;
          data_enviar.clv_his = 1;
          this.InfonavitService.cortsem(data_enviar)
            .subscribe(
              (resp) => {
                if (resp.clv_estatus === 1) {
                  this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: resp.messageDetails });
                 // this.dspcortesemanal = false;
                  this.cancelarcorte()
                  this.Filtrar();
                }
                if (resp.clv_estatus === 3) {
                  setTimeout(() => { // Agregar un pequeño retraso con setTimeout
                    this.confirmationService.confirm({
                      message: 'El corte de <b>INFONAVIT</b> de la Semana <b>' + this.selectedSemana.id_semana + '</b> ya fue bajado anteriormente. <b>¿Desea volver a bajarlo?</b> De ser así, se borrarán todos los registros de la semana seleccionada y se ingresarán todos los cambios',
                      header: 'Confirmación de edición de estatus',
                      icon: 'pi pi-info-circle',
                      accept: () => {

                        data_enviar.clv_his = 2;
                        this.InfonavitService.cortsem(data_enviar)
                          .subscribe(
                            (resp) => {
                              this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: resp.messageDetails });
                              this.cancelarcorte();
                              this.Filtrar();
                              //this.dspcortesemanal = false;
                            },
                            (error) => {
                              console.error('Error al volver a bajar caja de ahorro:', error);
                              this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Ocurrió un error al procesar la solicitud.' });
                            }
                          );
                        this.Filtrar();
                      },
                      reject: () => {
                        this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
                      }
                    });
                  }, 800); // 100 milisegundos de retraso
                }

              },
              (error) => {
                console.error('Error al bajar caja de ahorro:', error);
                this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Ocurrió un error al procesar la solicitud.' });
              }
            );
        },
        reject: () => {
          this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
        }
      });
    }
  }


  crearNuevo(){
    this.dspDeduccion=true;
    this.reactiveforminfonavit.controls['clv_operacion'].setValue(1);
    this.texto_operacion = 'Agregar Nueva Deduccion ';
    this.reactiveforminfonavit.controls['id_ciudad_hm'].disable();
    this.reactiveforminfonavit.controls['cod_chofer'].enable();
    this.reactiveforminfonavit.controls['num_anio'].enable();
    this.reactiveforminfonavit.controls['selectedSemana'].enable();
  }


  pdialogeditarDeduccion(){
    this.dspDeduccion=true;
    this.texto_operacion = '';
  }


  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  cargaListaChoferes() {
    this.dsblstColaboradores = true;
    this.lstChoferes = [];
    this.bonoRecomendacionService.getListadoChoferesGeneral(this.id_ciudad_hm).subscribe((resp) => {
      this.lstChoferes = resp;
      setTimeout(() => {
        this.dsblstColaboradores = false;
      }, 100);
    },
      (error) => {
        this.dsblstColaboradores = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Colaboradores');
      });
  }



  cargaInicial() {
    this.dsblstCiudades = true;
    this.loading = true;
    this.CiudadhmService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudad = resp.slice(); // Crear una copia independiente
      this.lstCiudad2 = resp.slice(); // Crear una copia independiente
      const index = this.lstCiudad.findIndex(item => item.nombre === 'TODAS LAS CIUDADES');
      if (index !== -1) {
        this.lstCiudad.splice(index, 1);
      }
      this.lstCiudad.push({ id: 0, nombre: 'TODAS LAS CIUDADES', clave_estado: '', id_ciudad_principal: 0, clave_tipoTransporte: 0 });
      setTimeout(() => {
        this.loading = false;
        this.dsblstCiudades = false;
      }, 300);
    },
    (error) => {
      this.loading = false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Ciudades');
    });
  }


  changedp(event: any) {
    let eve = event.value;
    //this.lstCiudad2 = [];
    let choferSeleccionado = this.lstChoferes.find(chofer => chofer.cod_chofer === eve);
    if (choferSeleccionado) {
        this.reactiveforminfonavit.controls['id_ciudad_hm'].setValue(choferSeleccionado.id_ciudad_hm);
        this.reactiveforminfonavit.controls['nombreCiudad'].setValue(choferSeleccionado.desc_ciudad_hm);
        this.reactiveforminfonavit.controls['nom_chofer'].setValue(choferSeleccionado.nombre_completo);
       // this.reactiveforminfonavit.controls['nombreChofer'].setValue(choferSeleccionado.nombre_completo);
    } else {
      this.mssgListaCiudades = 'El colaborador no cuenta con ciudad favor de verificar';
    }
}




guardarDeduccion(){
  let choferSeleccionado = this.lstChoferes.find(chofer => chofer.cod_chofer === this.reactiveforminfonavit.controls['cod_chofer'].value);
  let nombreChofer = choferSeleccionado ? choferSeleccionado.nombre_completo : ''
  let data_enviar: IPostInfonavit = {
    idx: this.reactiveforminfonavit.controls['idx'].value,
    cod_chofer: this.reactiveforminfonavit.controls['cod_chofer'].value,
    id_ciudad_hm: this.reactiveforminfonavit.controls['id_ciudad_hm'].value,
    num_anio: this.reactiveforminfonavit.controls['num_anio'].value,
   // id_semana: this.reactiveforminfonavit.controls['selectedSemana'].value.id_semana,
    imp_deduccion: this.reactiveforminfonavit.controls['imp_deduccion'].value,
    clv_operacion:  this.reactiveforminfonavit.controls['clv_operacion'].value,
    cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod,
};
this.confirmationService.confirm({
  message: '¿Está seguro de guardar la <b>DEDUCCION DE INFONAVIT</b>  del Colaborador <b>' + nombreChofer + '</b>?',
  header: 'Confirmación antes de guardar deduccion',
  icon: 'pi pi-info-circle',
  accept: () => {
    this.InfonavitService.postinfonavit(data_enviar).subscribe((resp) => {
      //const resultado = Array.isArray(resp) ? resp[0] : resp;
      if (resp.clv_estatus == 1) {
        this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Se registro correctamente' });
        this.dspDeduccion = false;
        this.cancelarAgregar();
        this.Filtrar();

      }
      else if (resp.clv_estatus == -2) {
        this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resp.messageDetails });
      }
    });
  },
  reject: (type: any) => {
    switch (type) {
      case ConfirmEventType.REJECT:
        this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
        break;
      case ConfirmEventType.CANCEL:
        this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
        break;
    }
  }
});
}



cancelarAgregar() {
  this.reactiveforminfonavit.controls['cod_chofer'].setValue(0);
  this.reactiveforminfonavit.controls['selectedSemana'].setValue([]);
  this.reactiveforminfonavit.controls['id_ciudad_hm'].setValue(0);
  this.reactiveforminfonavit.controls['imp_deduccion'].setValue(0);
  this.reactiveforminfonavit.controls['num_anio'].setValue(new Date().getFullYear());
  this.dspDeduccion = false;
  }



  saveAsExcelFile(buffer: any, fileName: string): void {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_exportar_" + dateFormat + EXCEL_EXTENSION);
  }



  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '. <b>Contacte al Administrador.</b>';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }



  mostrarDialogoInformativoSincontacteadmi(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario ;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  // ----------------reportes  ------------
  abrirModalReportes() {
    this.dbsReportes = true;
    this.inicializarFechasFormateadas();
  }


  cancelarModalReportes() {
    this.dbsReportes = false;
    // Inicio Reporte 1
    this.selectedMesReporte1 = {
      num_mes: 0,
      nombre: ''
    }
    this.anioReporte1 = null;
    this.selectedCiudad = {
      id: -1,
      nombre: '',
      clave_Estado: '',
      id_ciudad_principal: 0
    };
    this.fecha_fin = null;
    this.selectedSemanaReporte = {
      idx: 0,
      id_semana: 0,
      nom_semana: '',
      anio: 0,
      fec_inicio: '',
      fec_fin: '',
      clv_activo: 0
    };
    this.id_ciudad_hm_rpt =0;
  }


  editarDeduccion(data: IInfonavit) {
  //  const selectedWeek = this.lstSemanas.find(semana => semana.id_semana === data.id_semana);

    this.texto_operacion = 'Editar Deduccion ';
    this.dspDeduccion = true;

    this.reactiveforminfonavit.controls['idx'].setValue(data.idx);
    this.reactiveforminfonavit.controls['cod_chofer'].setValue(data.cod_chofer);
    this.reactiveforminfonavit.controls['id_ciudad_hm'].setValue(data.id_ciudad_hm);
    this.reactiveforminfonavit.controls['num_anio'].setValue(data.num_anio);
    this.reactiveforminfonavit.controls['imp_deduccion'].setValue(data.imp_deduccion);
   // this.reactiveforminfonavit.controls['selectedSemana'].setValue(selectedWeek);
    this.reactiveforminfonavit.controls['clv_operacion'].setValue(2);
     this.reactiveforminfonavit.controls['id_ciudad_hm'].disable();
     this.reactiveforminfonavit.controls['cod_chofer'].disable();
     this.reactiveforminfonavit.controls['num_anio'].disable();
    // this.reactiveforminfonavit.controls['selectedSemana'].disable();
  }

  //confirmarcambiarestatus(){}



  mostrardesgloce(data: IInfonavit){
    this.loading = true;
    this.InfonavitService.gethisifonavit(data.cod_chofer).subscribe((resp)=>{
      this.lsthisInfonavit=resp;
      //  setTimeout(() => {
      // }, 600);
      this.loading = false;
      this.dsphistorico=true;
      this.nombre_completo= data.nom_chofer;
      this.cod_chofer = data.cod_chofer;
      this.imp_total = data.imp_deduccion;
    },
      (error) => {
        this.loading = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar el historial de deducciones');
      });
  }


  cancelarVerHistorico(){
    this.dsphistorico= false;
    this.lsthisInfonavit=[];
    this.nombre_completo= '';
    this.cod_chofer = 0;
    this.imp_total = 0;
  }


  confirmarcambiarestatus(data: IInfonavit){
  let resultado: IResultadoGeneral;
    let data_enviar:  IPostInfonavit = {
      idx: data.idx,
      cod_chofer: data.cod_chofer,
      id_ciudad_hm: data.id_ciudad_hm,
     // id_semana: data.id_semana,
      num_anio: data.num_anio,
      imp_deduccion: data.imp_deduccion,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod,
      clv_operacion: this.clv_operacion = 3,
    }
    this.confirmationService.confirm({
      message: '¿Está seguro que eliminar <b>LA DEDUCCION DE INFONAVIT</b>  del Colaborador <b>' + data.nom_chofer + '</b>?',
      header: 'Confirmación de edición de estatus',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.InfonavitService.postinfonavit(data_enviar).subscribe((resp) => {
          // Verificamos si la respuesta es una lista y tomamos el primer elemento
          const resultado = Array.isArray(resp) ? resp[0] : resp;
          if (resultado.clv_estatus == 1) {
              this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Se registro correctamente' });
              this.Filtrar();
          } else if (resultado.clv_estatus == -2) {
              this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resultado.messageDetails });
          }
      });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }



  inicializarFechasFormateadas() {
    //hacer globales
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
    this.fecha_del_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha que no se modifica
    this.fecha_final_dia = new Date(dateFormat + 'T00:00:00');
    //Fecha para restar los 3 dias
    let minus3days = new Date(this.fecha_final_dia);
    this.fecha_minus3 = new Date(minus3days.setDate(new Date(minus3days).getDate() - 4));
    //Fecha del dia real, no se mueve
    this.fecha_dia_real = dateFormat;
    //Reportes
    let num_mes: number = +getMonth;
    this.meses.map((mes) => {
      if (mes.num_mes == num_mes) {
        this.selectedMesReporte1.nombre = mes.nombre;
        this.selectedMesReporte1.num_mes = mes.num_mes;
      }
    });
    this.anioReporte1 = getYear;
  }


  // metodo para exportar a excel
  exportarExcel( ) {
    this.deshabilitarbtnexportar = true;
    if (this.id_ciudad_hm_rpt == null || typeof this.id_ciudad_hm_rpt === 'undefined' || !this.selectedSemanaReporte || this.selectedSemanaReporte.id_semana == null || typeof this.selectedSemanaReporte.id_semana === 'undefined') {
      this.mostrarDialogoInformativoSinInformacion('<b>Informativo</b> Debe seleccionar una semana para poder filtrar');
      this.deshabilitarbtnexportar = false;
    } else  {
      this.InfonavitService.rptInfonavit(this.selectedSemanaReporte.anio, this.selectedSemanaReporte.id_semana,this.id_ciudad_hm_rpt,)
        .subscribe((resp: IInfonavit[]) => {
          if (resp.length === 0 || typeof resp === 'undefined') {
            this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> debes de seleccionar una semana y ciudad con registros para poder exportar la informacion del Catalogo(Excel)');

            this.deshabilitarbtnexportar = false;
          } else {
            import("xlsx").then(xlsx => {
              const worksheet = xlsx.utils.json_to_sheet(resp);
              const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
              const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array"
              });

              this.saveAsExcelFile(excelBuffer, "Reporte_Colaboradores_Descuento_Infonavit"+ '_'+ 'semana'+'_'+this.selectedSemanaReporte.id_semana+  '_' + 'añio'+'_'+ this.selectedSemanaReporte.anio );
            });
            setTimeout(() => {
              this.ButtonExportExcel = false;
              this.deshabilitarbtnexportar = false;
              //this.cancelarModalReportes()
            }, 300);
          }
        },
          (error) => {
            this.ButtonExportExcel = false;
            this.deshabilitarbtnexportar = false;
            this.fecha_fin = null;
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en emitir el reporte de Estatus de Facturación.' });
          });
    }
  }



  mostrarDialogoInformativo(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario + '.';
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        this.messageService.clear();
      },
      key: "sinInformacionDialog"
    });
    this.loading = false;
    this.messageService.clear();
  }


}
