<p-toast position="top-right" key="msj"></p-toast>
<!--<p-messages></p-messages>-->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>

<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false">
</p-confirmDialog>
<div class="d-flex justify-content-center">
  <div class="container-fluid" style="width: 95%;">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card card-shadow sinborde">
          <div class="card-header sinborde" style="background: none; ">
            <div class="cardicono rounded">
              <i class="pi pi-money-bill mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">
              <div style="display:inline"> Gestion de</div>

              <div *ngIf="dia.length > 0" style="color:rgb(0, 191, 255);display:inline;" class="ml-3">Finiquito</div>
              <!-- - {{dia|date:'dd/MM/yyyy'}}
                 -->
            </header>

            <div class="row d-flex justify-content-between">
              <div class="col-md-12">
                <!-- -------------fecha del filtro---------------- -->
                <div class="form-group row mt-5">

                  <!-- filtro de semanas -->
                  <div class="col-md-3 ml-4">
                    <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                      placeholder="Seleccione Semana*" [(ngModel)]="selectedSemana" optionLabel="nom_semana"
                      [showClear]="false">
                      <ng-template let-target pTemplate="item">
                        <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                          pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                          <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                        </div>
                      </ng-template>
                    </p-dropdown>
                    <div *ngIf="selectedSemana!=null">
                      <div class="row">
                        <div style="color: green;" class="col-sm-6 text-left "><b>Inicio:
                            {{selectedSemana.fec_inicio | date: 'dd/MM/yyyy' }}</b></div>
                        <div style="color: green;" class="col-sm-6 text-right "><b>Fin:
                            {{selectedSemana.fec_fin | date: 'dd/MM/yyyy' }}</b></div>
                      </div>
                    </div>
                  </div>

                  <!-- filtro de ciudades -->
                  <div class="col-md-2 mr-3 mt-2">
                    <p-dropdown [(ngModel)]="id_ciudad_hm" [options]="lstCiudad" optionLabel="nombre" optionValue="id"
                      placeholder="Filtrar por Ciudad" [showClear]="false" [disabled]="dsblstCiudades">
                    </p-dropdown>
                  </div>

                  <!-- ----------boton principal para el filtro  inicial------------------- -->
                  <div class="col-md-1 ">
                    <button pButton pRipple type="button" (click)="Filtrar();" label="Filtrar" icon="pi pi-filter"
                      iconPos="left"></button>
                  </div>
                  <div class="col-md-1 "></div>
                  <div class=" ml-4 col-md-3 d-flex justify-content-end">
                    <div>
                      <div class="col-md-6">
                        <div class="row d-flex justify-content-around">
                          <div class="col-md-9">
                            <p-calendar [(ngModel)]="anio_filtro" view="year" [showIcon]="true" dateFormat="yy"
                              inputId="yearpicker" [style]="{'width':'100%','height':'80%'}"
                              pTooltip="Año de Semanas a Consultar" tooltipPosition="top"></p-calendar>
                          </div>
                          <div class="col-md-3">
                            <button pButton pRipple label="Cargar" class="p-button-help" icon="pi pi-filter"
                              (click)="cargarListadoDeSemanasAnio_cast(anio_filtro);"
                              pTooltip="Cargar Semanas del Año"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ----------boton principal de los reportes en excel------------------- -->
                  <div class="  col-md-1 ml-4 ">
                    <button type="button" pButton pRipple class="p-button-success " pTooltip="Exportar Catalogo a EXCEL"
                      tooltipPosition="right" (click)="abrirModalReportes()">
                      <i class="pi pi-file-excel"></i> Exportar
                    </button>
                  </div>
                </div>
              </div>
            </div>



            <div class="card-content">
              <div class="row mt-3">
                <!-- informacio  -->
                <p-table #dt1 [value]="lstFiniquito" [tableStyle]="{'min-width': '50rem'}"
                  styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                  scrollDirection="horizontal" responsiveLayout="scroll"
                  [globalFilterFields]="['nom_chofer','desc_puesto','desc_ciudad_hm','cod_chofer']" [paginator]="true"
                  [rows]="30" [rowsPerPageOptions]="[10,15,20,30,50]" [showCurrentPageReport]="true"
                  [resizableColumns]="false" currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
                  [loading]="loading">
                  <ng-template pTemplate="caption">
                    <div class="flex justify-content-between">
                      <div>
                        <button pButton pRipple type="button" (click)="crearNuevo();" label="Nuevo Finiquito"
                          icon="pi pi-plus" [style]="{'width':'100%'}" iconPos="left" class="info">
                        </button>
                      </div>

                      <div class="col-md-3">
                        <p-columnFilter field="clv_estatus_opciones" matchMode="contains" [showMenu]="false">
                          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-dropdown [ngModel]="value" [options]="lstEstatus" optionLabel="nombre" optionValue="id"
                              (onChange)="filter($event.value)" placeholder="Filtrar por Estatus" [showClear]="true">
                            </p-dropdown>
                          </ng-template>
                        </p-columnFilter>
                      </div>



                      <div class="p-input-icon-left p-ml-auto" *ngIf="habilitaragregar">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                          placeholder="Buscar: Codigo chofer, Nombre y Puesto"
                          pTooltip="Buscar por nombre del colaborador, ciudad de operacion o tipo transporte"
                          tooltipPosition="top" />
                      </div>
                      <div class="p-input-icon-left p-ml-auto" *ngIf="!habilitaragregar">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                          placeholder="Buscar: Codigo chofer, Nombre y Puesto"
                          pTooltip="Buscar por nombre del colaborador, ciudad de operacion o tipo transporte"
                          tooltipPosition="top" />
                      </div>
                    </div>

                  </ng-template>

                  <!-- tabulado -->
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center" pSortableColumn="cod_chofer">#Chofer<p-sortIcon
                          field="cod_chofer"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="nom_chofer">Colaborador<p-sortIcon
                          field="nom_chofer"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="id_ciudad_hm">Ciudad<p-sortIcon
                          field="id_ciudad_hm"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="desc_puesto">Puesto<p-sortIcon
                          field="desc_puesto"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="id_finiquito">T.finiquito<p-sortIcon
                          field="id_finiquito"></p-sortIcon></th>
                      <th class="text-center">Año</th>
                      <th class="text-center">Fecha alta</th>
                      <th class="text-center">Fecha baja</th>
                      <th class="text-center">Id semana</th>
                      <th class="text-center">#dias laborados año</th>
                      <th class="text-center">Motivo</th>
                      <th class="text-center">Imp.Finiquito</th>
                      <th style="text-align: center;">Reporte PDF</th>
                      <th class="text-center">Estatus</th>
                      <th class="text-center">Gestion</th>
                      <th class="text-center">Opciones</th>
                    </tr>

                  </ng-template>
                  <ng-template pTemplate="body" let-data let-index>
                    <tr>
                      <td class="text-center"><b>{{ data.cod_chofer }}</b></td>
                      <td class="justify-content-left"><b>{{ data.nom_chofer }}</b></td>
                      <td class="text-center">{{data.desc_ciudad_hm}}</td>
                      <td class="text-center"
                        [ngStyle]="{'color': data.desc_puesto ==='DRIVER'? 'rgba(60,  148, 224)' : 'rgba(60, 224, 122  )'}">
                        <b>{{ data.desc_puesto }}</b>
                      </td>
                      <td class="text-center">
                        <span *ngIf="data.id_finiquito === 2">Renuncia</span>
                        <span *ngIf="data.id_finiquito === 1">Despido</span>
                        <span *ngIf="data.id_finiquito === 0">Sin estatus</span>
                      </td>
                      <td class="text-center">{{ data.num_anio }}</td>
                      <td class="text-center"><b>{{ data.fec_alta | date: 'dd/MM/yyyy' }}</b></td>
                      <td class="text-center"><b>{{ data.fec_baja | date: 'dd/MM/yyyy' }}</b></td>
                      <td class="text-center">{{ data.id_semana }}</td>
                      <td class="text-center"><b>{{ data.num_dias_laborados_anio }}</b></td>
                      <td class="text-justify" style="width: 200px; min-width: 200px; font-size:13px;">
                        {{data.desc_finiquito
                        | slice : 0:80 }}<button type="button" class="btn p-button-text" (click)="verMas(data);"
                          pTooltip="ver mas" tooltipPosition="top">
                          <i class="pi pi-comment color-icon mr-3" style="font-size: 1rem; color: #3B82F6;"></i>
                        </button></td>

                      <td class="text-center" style="min-width:200px ;max-width: 200px; color: rgb(19, 161, 19);"
                        [ngClass]="{'ajustarcelda':true}">
                        <p class="my-0"><b>{{data.imp_finiquito | currency: 'USD'}}</b>
                          <span class="input-group-addon ml-2 my-0" (click)="mostrardesgloce(data)"
                            pTooltip="Desgloce del finiquito">
                            <i class="pi pi-info-circle color-icon mr-3" style="font-size: 1.1rem; color: #3B82F6;">
                            </i>
                          </span>
                        </p>
                      </td>
                      <td>
                        <div class="d-flex justify-content-center">
                          <i class="pi pi-file-pdf" style="font-size: 1.5rem; color: rgb(189, 12, 12); cursor: pointer;"
                            (click)="reciboPDF(data.clave_chofer, data.nom_chofer)" tooltipPosition="top"
                            pTooltip="Descargar Recibo">
                          </i>
                        </div>
                      </td>


                      <td class="text-center">
                        <span style="color: rgb(165, 165, 165);" *ngIf="data.clv_estatus_opciones === 1"><b>Pendiente autorizar
                          </b></span>
                        <span style="color: rgb(67, 192, 35);" *ngIf="data.clv_estatus_opciones === 2"><b>Aceptado </b></span>
                        <span style="color: rgb(223, 166, 44);" *ngIf="data.clv_estatus_opciones === 3"><b>Rechazado </b></span>
                      </td>

                      <!-- <td class="text-center" [ngStyle]="{'color': data.clv_activo === 1 ? 'green' : 'orange'}">
                        <b>{{ data.clv_activo === 1 ? 'Prima vacacional aprobada' : 'Requiere aprobación' }}</b>
                      </td> -->
                      <td>
                        <div class="d-flex justify-content-around">
                          <div class="ml-2">
                            <button [disabled]="data.clv_estatus_opciones == 2" class="btn btn-success" tooltipPosition="top" pTooltip="Aceptar finiquito"
                              (click)=" aprobarFiniquito(data)">
                              <i class="pi pi-check"></i>
                            </button>
                          </div>


                          <div class="ml-2">
                            <button [disabled]="data.clv_estatus_opciones != 1"  class="btn btn-warning" tooltipPosition="top" pTooltip="Rechazar finiquito"
                              (click)="rechazarFiniquito(data)">
                              <i class="pi pi-times"></i>
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-content-around">
                          <div class="ml-2">
                            <button [disabled]="data.clv_estatus_opciones == 2" class="btn btn-primary" tooltipPosition="top" pTooltip="Editar finiquito"
                              (click)=" editarDeduccion(data)">
                              <i class="pi pi-pencil"></i>
                            </button>
                          </div>


                          <div class="ml-2">
                            <button [disabled]="data.clv_estatus_opciones == 2" class="btn btn-danger" tooltipPosition="top" pTooltip="Eliminar finiquito"
                              (click)="EliminarRegistro(data)">
                              <i class="pi pi-trash"></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <tr>
                      <td colspan="2" class="text-right"></td>
                      <td colspan="13" class="text-right" style="color: rgb(169, 12, 12);">Finiquito total</td>
                      <td style="color: rgb(13, 164, 13);">{{ imp_total_genral | currency: 'USD'}}</td>
                    </tr>
                  </ng-template>
                </p-table>

                <!-- pie de pagina -->
              </div>
              <div class=" mt-3 d-flex justify-content-center">
                <p class="text-left informativo"
                  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(67, 192, 35); font-weight: normal; font-size: 0.8rem;">
                  <b>ACEPTADA</b> El colaborador acepto el finiquito
                </p>
                <p class="text-left informativo"
                  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(223, 166, 44); font-weight: normal; font-size: 0.8rem;">
                  <b>RECHAZDA</b> El colaborasdor rechazo el finiquito
                </p>
                <p class="text-left informativo"
                  style="display: inline; padding-left: 10px; border-left: 5px solid rgb(78, 78, 78); font-weight: normal; font-size: 0.8rem;">
                  <b>Pendiente autorizar</b> Se encuentra a la espera de confirmacion

                </p>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>



    <!-- pdialog de agregar  -->
    <p-dialog [resizable]="false" [draggable]="false"  (onHide)="cancelarAgregar()"
      [(visible)]="dspDeduccion" [style]="{width: '70vw','height':'830px','overflow-x': 'hidden' } " [transitionOptions]="'0ms'">
      <ng-template pTemplate="header">
        {{ texto_operacion}}
      </ng-template>
      <ng-template pTemplate="content">
        <div style="height: 700px; overflow-y: auto;">
          <div class="card-content ">
            <form class="form_cliente" [formGroup]="reactiveforminfonavit" (ngSubmit)="guardarFiniquito()">
              <div class="ml-2">
                <div class="row mt-1 d-flex justify-content-start">
                  <div class="col-sm-6">
                    <span class="title-light">Seleccione la semana a pagar y el colaborador
                      <i class="pi pi-cog" style="size: 2rem;">
                      </i>
                    </span>
                  </div>
                </div>

                <div class="row  mt-3">
                  <!-- colaboradores  que recibira el apoyo-->
                  <div class="row  d-flex justify-content-start ">
                    <div class="row col-md-6">

                        <div class="col-md-3  ">
                          <label class="col-form-label color-font">
                            <span class="input-group-addon">
                              <i class="pi pi-user color-icon " style="font-size: 2rem"></i>
                            </span>
                            Acreedor
                          </label>
                        </div>
                        <div class="col-md-9">
                          <p-dropdown [options]="lstChoferes" optionLabel="nombre_completo" formControlName="cod_chofer"
                            autoWidth="false" [showClear]="false" [filter]="true" [filterBy]="'nombre_completo'"
                            placeholder="Seleccione Colaborador" [style]="{'width':'100%' , 'margin-top': '15px'}"
                            optionValue="cod_chofer" (onChange)="changedp($event)">

                          </p-dropdown>
                        </div>

                    </div>
                    <div class=" row col-md-6">

                        <div class="col-md-3  ">
                          <label class="col-form-label color-font ">
                            <span class="input-group-addon">
                              <i class="pi pi-globe color-icon " style="font-size: 2rem"></i>
                            </span>
                            Ciudad
                          </label>
                        </div>
                        <div class="col-md-9">
                          <p-dropdown [options]="lstCiudad2" optionLabel="nombre" formControlName="id_ciudad_hm"
                            autoWidth="false" [showClear]="false"
                            placeholder="Seleccione Ciudad del colaborador acreedor"
                            [style]="{'width':'100%' , 'margin-top': '15px'}" optionValue="id"
                            pTooltip="{{mssgListaCiudades}}">
                          </p-dropdown>
                        </div>

                    </div>
                  </div>

                  <div class="row  d-flex justify-content-start">
                    <div class="row col-md-6">
                        <div class="col-md-3 ">
                          <label class="col-form-label color-font">
                            <span class="input-group-addon">
                              <i class="pi pi-money-bill color-icon " style="font-size: 2rem"></i>
                            </span>
                            Tipo de Finiquito
                          </label>
                        </div>
                        <div class="col-md-9 mt-2">
                          <p-dropdown [options]="lisTipoFiniquito" optionLabel="desc_finiquito_list"
                            formControlName="id_finiquito" autoWidth="false" [showClear]="false"
                            placeholder="Tipo de finiquito" [style]="{'width':'100%' , 'margin-top': '14px'}"
                            optionValue="id_finiquito">
                          </p-dropdown>
                        </div>

                    </div>
                    <div class=" row col-md-6 mt-2">

                      <div class="col-md-3 ">
                        <label
                          style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                          <span class="input-group-addon"> <i class="pi pi-calendar color-icon "
                              style="font-size: 2rem; color: #3B82F6;"></i>
                          </span>
                          Año
                        </label>
                      </div>
                      <div class="col-md-9 mt-2">
                        <select class="form-control" formControlName="num_anio">
                          <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                        </select>
                      </div>

                    </div>
                  </div>


                  <div class="row  d-flex justify-content-start">
                    <div class="row col-md-6 mt-2">

                      <div class="col-md-3 mt-2">
                        <label
                          style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                          <span class="input-group-addon "> <i class="pi pi-calendar color-icon"
                              style="font-size: 2rem; color: #3B82F6;"></i>
                          </span>
                          ALTA
                        </label>
                      </div>
                      <div class="col-md-9 mt-3">
                        <input type="date" class="form-control sinborde" pTooltip="Fecha de alta del colaborador"
                          tooltipPosition="top" formControlName="fec_alta" (onChange)="changedp($event)">

                      </div>

                    </div>



                    <div class="row col-md-6 mt-2">
                      <div class="col-md-3">
                        <label
                          style="font-weight: 600; font-family: 'Poppins', sans-serif;">
                          <span class="input-group-addon"> <i class="pi pi-calendar color-icon "
                              style="font-size: 2rem; color: #3B82F6;"></i>
                          </span>
                          BAJA
                        </label>
                      </div>
                      <div class="col-md-9 mt-2">
                        <input type="date" class="form-control sinborde" pTooltip="Fecha de baja del colaborador"
                          tooltipPosition="top" formControlName="fec_baja" (onChange)="changedp($event)">

                      </div>
                    </div>


                  </div>





                  <div class="row   d-flex justify-content-start ">
                    <div class="form-group col-md-6 mt-2">


                      <div class="row">
                      <div class="col-md-12 mt-4">
                        <label style="color: rgb(22, 22, 176);" [ngClass]="{'texto_sm':true}"
                          class="label-header"><b>Semana a pagar</b></label>
                      </div>
                    </div><div class="row">
                      <div class="col-md-12   ">
                        <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'95%'}"
                          placeholder="Seleccione Semana*" formControlName="selectedSemanaagregar" optionLabel="nom_semana"
                          [showClear]="false">
                          <ng-template let-target pTemplate="item">
                            <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                              pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                              <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                            </div>
                          </ng-template>
                        </p-dropdown>
                        <div *ngIf="reactiveforminfonavit.controls['selectedSemanaagregar'].value.id_semana!=null">
                          <div class="row ">
                            <div style="color: green;" class="col-md-6 d-flex justify-content-start ml-3"><b>Inicio:
                                {{reactiveforminfonavit.controls['selectedSemanaagregar'].value.fec_inicio | date: 'dd/MM/yyyy' }}</b></div>
                            <div style="color: green;" class="col-md-5 d-flex justify-content-end mr-2"><b>Fin:
                                {{reactiveforminfonavit.controls['selectedSemanaagregar'].value.fec_fin | date: 'dd/MM/yyyy' }}</b></div>
                          </div>
                        </div>
                      </div>
                    </div>



                      <div class="row">
                        <div class="col-md-5 mt-4">
                          <label style="color: rgb(22, 22, 176);" [ngClass]="{'texto_sm':true}"
                            class="label-header"><b>#Dias vacaciones pendientes</b></label>
                        </div>

                        <div class="col-md-7  d-flex justify-content-start">
                          <input formControlName="num_dias_vacaciones" type="number" placeholder="0" min="0"
                            class="form-control sinborde " pTooltip="Ingrese los días pendientes de vacaciones"
                            tooltipPosition="top " [style]="{'width':'90%' , 'margin-top': '20px'}">
                        </div>
                      </div>


                      <div class="row">
                        <div class="col-md-5 mt-4">
                          <label style="color: rgb(22, 22, 176);" [ngClass]="{'texto_sm':true}"
                            class="label-header"><b>Caja de ahorro</b></label>
                        </div>

                        <div class="col-md-7 d-flex justify-content-start">
                          <input formControlName="imp_cajaahorro" type="text" placeholder="0"
                            class="form-control sinborde " pTooltip="Ingrese los días pendientes de vacaciones"
                            tooltipPosition="top " [style]="{'width':'90%' , 'margin-top': '20px'}">
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-5 mt-4">
                          <label style="color: rgb(22, 22, 176);" [ngClass]="{'texto_sm':true}"
                            class="label-header"><b>Compensación(demanda)</b></label>
                        </div>

                        <div class="col-md-7  d-flex justify-content-start">
                          <input formControlName="imp_Compensaciondemanda" type="number" placeholder="0" min="0"
                            class="form-control sinborde " pTooltip="Ingrese los días pendientes de vacaciones"
                            tooltipPosition="top " [style]="{'width':'90%' , 'margin-top': '20px'}">
                        </div>
                      </div>
                    </div>

                    <div class="row col-md-6 mt-3">
                      <label style="color:  rgb(22, 22, 176); font-weight: bold;">Motivo de la baja</label>
                      <textarea rows="5" cols="40" style="width: 95%;" pInputTextarea formControlName="desc_finiquito"></textarea>
                    </div>



                  </div>

                </div>

                <!-- --------------fin drodowns------------- -->
                <div class="row d-flex ">
                  <div class="col-md-5  mt-4 justify-content-start">
                    <!-- <p><span style="font-weight: bold; ">Última&nbsp;Modificación:</span></p>
                 <p style="margin-top: 0px;  color: rgb(181, 17, 50);"> -->
                    <!-- <b>{{reactiveVacacionesForm.get('usuarioMod')?.value}}</b>
                 </p>-->
                  </div>

                  <div class="col-md-3 mt-7 justify-content-center">
                    <button class="btn btn-primary form-control" type="submit" [disabled]="  !reactiveforminfonavit.controls['id_finiquito'].valid
                     ||!reactiveforminfonavit.controls['desc_finiquito'].value||!reactiveforminfonavit.controls['selectedSemanaagregar'].value.id_semana
                            " pTooltip="Almacenar información" tooltipPosition="right">
                      <span class="text-white">Guardar</span>
                      <i class="pi pi-save text-white"></i>
                    </button>
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarAgregar()"></button>
      </ng-template>
    </p-dialog>


    <!-- pdialog de comentario  -->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspVerMas" [style]="{width: '35vw','height':'430px'}"
      (onHide)="verMenos()" [transitionOptions]="'0ms'" styleClass="card-shadow">
      <ng-template pTemplate="header">
         Motivo de la renuncia o despido
      </ng-template>
      <ng-template pTemplate="content">
        <form class="form_cliente" [formGroup]="reactiveforminfonavit" (ngSubmit)="verMenos()">
          <div class="row  pb-2">

            <!-- tipo de incapacuidad -->

            <div class="row d-flex mt-3">
              <label style=" font-weight: bold;" class="title-light">Colaborador: {{this.nombre_chofer}}</label>
              <textarea rows="5" cols="50" pInputTextarea formControlName="desc_finiquito"></textarea>
            </div>
          </div>
          <div class="mt-2 d-flex justify-content-center">
            <button type="submit" class="btn btn-lg btn-success" pTooltip="Registrar finiquito">ACEPTAR</button>
          </div>
        </form>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="verMenos();"></button>
      </ng-template>
    </p-dialog>


    <!-- desgloce -->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDesgloce"
      [style]="{width: '65vw', height: '850px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="verMenosdesgloce()">
      <ng-template pTemplate="header">
        {{ texto_operacion }}
        <div *ngIf="nombre_registro">
          {{ nombre_registro }}
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <form class="form_cliente">
          <div class="mt-3 ">
            <span class="title-light mt-1">Información del Finiquito <i class="pi pi-cog"
                style="size: 2rem;"></i></span>
            <div class="deducciones-container">
              <div class="row d-flex mt-2">
                <label style="font-weight: bold;">
                  <span style="color: rgb(10, 10, 10);">Colaborador:</span>
                  <span style="color: rgb(30, 61, 233);">{{this.nom_chofer}}</span>
                  <span style="color: orange;">({{this.desc_puesto}})</span>
                  <div class="text-start">
                    <span style="color: green;" *ngIf="this.clv_estatus === 1">Renuncia Voluntaria</span>
                    <span style="color: red;" *ngIf="this.clv_estatus === 2">Despido</span>
                  </div>
                </label>
              </div>

              <div class="deduccion-item2 ">

                <span class="font-bold ml-2 texto_categoria">Sueldo diario: {{this.imp_salario |currency}}</span>
                <span class="font-bold ml-2 texto_categoria">Dias proporcionales vacaciones: {{this.num_dias_vacaciones}}</span>
                <span class="font-bold ml-2 texto_categoria">Dias proporcionales aguinaldo: {{this.dias_aguinaldo_proporcional}}</span>
                <!-- <span class="font-bold ml-2 texto_categoria mr-3">Importe&nbsp;</span> -->

              </div>
              <div class="deduccion-item2 ">



                <span class="font-bold ml-2 texto_categoria">Fecha alta: {{this.fec_alta |date: 'dd/MM/yyyy'}}</span>
                <span class="font-bold ml-2 texto_categoria">Fecha baja: {{this.fec_baja |date: 'dd/MM/yyyy'}}</span>
                <span class="font-bold ml-2 texto_categoria">Dias laborados del año de baja:{{this.num_dias_laborados_anio}}</span>
                <span class="font-bold ml-2 texto_categoria">Dias total laborados: {{dias_laborados}}</span>


              </div>
              <hr>
              <div class="row">
                <div class="row col-md-6 ml-2  mt-2">
                  <div class="deduccion-item ">
                    <span class="font-bold texto_categoria">Imp.proporcional aguinaldo</span>
                    <span class="texto_verde text-center texto_resaltado">{{imp_aguinaldo | currency}}&nbsp;&nbsp;</span>
                  </div>

                  <div class="deduccion-item ">
                    <span class="font-bold texto_categoria">Imp.proporcional vacaciones</span>
                    <span class="texto_verde text-center texto_resaltado">{{imp_vacaciones | currency }}&nbsp;&nbsp;</span>

                  </div>
                  <div class="deduccion-item ">
                    <span class="font-bold  texto_categoria">Imp.proporcional prima vacacional</span>
                    <span class="texto_verde text-center texto_resaltado">{{imp_prima | currency }}&nbsp;&nbsp;</span>
                  </div>


                  <hr>
                </div>
                <div class="row col-md-6 table-container ml-2">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Nomina</th>
                        <th scope="col">Ordinarios</th>
                        <th scope="col">No ordinarios</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Ingreso</th>
                        <td style="color: black;"><b>{{imp_rutas_pendientes | currency}}</b></td>
                        <td style="color: black;"><b>{{imp_noordinarios | currency}}</b></td>
                      </tr>
                      <tr>
                        <th scope="row">Viaticos</th>
                        <td style="color: black;"><b>{{imp_ordinarios_viaticos | currency}}</b></td>
                        <td style="color: black;"><b>{{imp_noordinarios_viaticos | currency}}</b></td>
                      </tr>
                      <tr>
                        <th scope="row">Hospedaje</th>
                        <td style="color: black;"><b>{{imp_ordinarios_hospedaje | currency}}</b></td>
                        <td style="color: black;"><b>{{imp_noordinarios_hospedaje | currency}}</b></td>
                      </tr>
                      <tr>
                        <th scope="row">Total</th>
                        <td class="texto_verde texto_resaltado">{{imp_ordinarios_hospedaje + imp_ordinarios_viaticos + imp_rutas_pendientes | currency}}</td>
                        <td class="texto_verde texto_resaltado">{{imp_noordinarios_hospedaje + imp_noordinarios_viaticos + imp_noordinarios | currency}}</td>
                      </tr>
                    </tbody>
                  </table>

                </div>

              </div>
            </div>


            <div class="row">
              <div class="col-md-6 ">
                <div class="deduccion-item ">
                  <span class="font-bold texto_categoria">Indemnización constitucional(3 meses) </span>
                  <span class="texto_verde text-center texto_resaltado">{{ imp_tres_meses | currency }}&nbsp;&nbsp;</span>
                </div>
                <div class="deduccion-item  ">
                  <span class="font-bold texto_categoria">20 dias por año</span>
                  <span class="texto_verde text-center texto_resaltado">{{ imp_indemnizacion | currency }}&nbsp;&nbsp;</span>
                </div>
                <div class="deduccion-item ">
                  <span class="font-bold texto_categoria">Prima de antigüedad</span>
                  <span class="texto_verde text-center texto_resaltado">{{ imp_prima_antiguedad | currency  }}&nbsp;&nbsp;</span>
                </div>
                <hr>
              </div>
              <div class="col-md-6 ">
                <div class="deduccion-item3 ">
                  <span class="font-bold  texto_categoria">Descuentos infonavit</span>
                  <span class="texto_verde text-center texto_resaltadoRed">-{{ imp_infonavit | currency }}&nbsp;&nbsp;</span>
                </div>
                <div class="deduccion-item3 ">
                  <span class="font-bold texto_categoria">Descuentos Varios</span>
                  <span class="texto_verde text-center texto_resaltadoRed">-{{ imp_descuentosvarios | currency }}&nbsp;&nbsp;</span>
                </div>
                <div class="deduccion-item3 ">
                  <span class="font-bold texto_categoria">Descuento prestamos personales</span>
                  <span class="texto_verde text-center texto_resaltadoRed">-{{ imp_prestamos | currency}}&nbsp;&nbsp;</span>
                </div>
                <hr>
              </div>
            </div>




            <div class="row">
              <div class="col-md-6 ">

                <div class="deduccion-item ">
                  <span class="font-bold texto_categoria">Imp.caja de ahorro</span>
                  <span class="texto_verde text-center texto_resaltado">{{ this.imp_cajaahorro | currency }}&nbsp;&nbsp;</span>
                </div>


              </div>

              <div class="col-md-6 ">

                <div class="deduccion-item">
                  <span class="font-bold texto_categoria">Compensacion(consiliacion)</span>
                  <span class="texto_verde text-center texto_resaltado"> {{ this.imp_Compensaciondemanda | currency}}&nbsp;&nbsp;</span>
                </div>


              </div>
            </div>
            <hr>
            <div class=" d-flex justify-content-end">
              <span><b>Total:&nbsp;&nbsp;</b></span>
              <span class="total-amount texto_verde texto_resaltado mr-3">{{ imp_finiquito | currency }}</span>
            </div>
          </div>
        </form>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="verMenosdesgloce();"></button>
      </ng-template>
    </p-dialog>




    <!-- p-dialog de reportes excel ------------ -->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
      [style]="{'width': '60vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarModalReportes()">
      <ng-template pTemplate="header">
        Generar Reporte De Excel
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <hr class="style-two">
          <div class="row">
            <div class="col-md-3">
              <p class="texto-pesado">Reporte de finiquito</p>
            </div>
            <div class="col-md-3">
              <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                placeholder="Seleccione Semana*" [(ngModel)]="selectedSemanaReporte" optionLabel="nom_semana"
                [showClear]="false">
                <ng-template let-target pTemplate="item">
                  <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                    pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                    <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                  </div>
                </ng-template>
              </p-dropdown>
              <div *ngIf="selectedSemanaReporte!=null">
                <div class="row">
                  <div style="color: green;" class="col-sm-6 text-left "><b>Inicio:
                      {{selectedSemanaReporte.fec_inicio | date: 'dd/MM/yyyy' }}</b></div>
                  <div style="color: green;" class="col-sm-6 text-right "><b>Fin:
                      {{selectedSemanaReporte.fec_fin | date: 'dd/MM/yyyy' }}</b></div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <p-dropdown [(ngModel)]="id_ciudad_hm_rpt" [options]="lstCiudad" optionLabel="nombre" optionValue="id"
                placeholder="Filtrar por Ciudad" [showClear]="false" [disabled]="dsblstCiudades">
              </p-dropdown>
            </div>
            <div class="col-md-2 ml-6">
              <button type="button" [disabled]="deshabilitarbtnexportar" class="btn btn-outline-success"
                style="width: 100%;" (click)="exportarExcel()" [disabled]="ButtonExportExcel">
                Exportar <i class="pi pi-file-export"></i>
              </button>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <div class="col-2 text-center">
              <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte1"></i>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarModalReportes();"></button>
      </ng-template>
    </p-dialog>
  </div>
