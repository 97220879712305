import { EventEmitter, Input, Output, ViewChild } from '@angular/core';
import ICredencialUsuario from '../models/credencial';
import { ISemanasHappyMile } from '../models/semanas';
import IpostTexto from '../models/postTexto';
import { Table } from 'primeng/table';
import { ICiudadPrincipalHM } from '../models/ciudadprincipal';
import { ICiudadHM } from '../models/ciudadhm';
import { IChoferBonoPermanencia } from '../models/choferbonopermanencia';
import { IBonoPermanenciaEnviar } from '../models/bono_enviar_permanencia';
import { IrespondBonoPermanencia } from '../models/respondBonoPermanencia';
import IBonoPermanenciaPost from '../models/postBonoPermanencia';
import { IErrorCode } from '../models/errorcode';
import { IResultadoGeneral } from '../models/resultadogeneral';
import { InombreCompletoChofer } from '../models/nombreCompletoChofer';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { BonopermanenciaService } from '../services/bonopermanencia.service';
import { SemanaService } from '../services/semana.service';
import { CiudadesprincipalesService } from '../services/ciudadesprincipales.service';
import { Component, OnInit,  } from '@angular/core';
import { BonoContratacionService } from '../services/bonocontratacion.serive';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import * as saveAs from 'file-saver';
import { CiudadhmService } from '../services/ciudadhm.service';
import { IApoyo } from '../models/apoyo';
@Component({
  selector: 'app-bonocontratacion',
  templateUrl: './bonocontratacion.component.html',
  styleUrls: ['./bonocontratacion.component.scss']
})
export class BonocontratacionComponent {
  @Input() isVisible: boolean = false; // Controla si el diálogo se muestra
  @Output() onClose = new EventEmitter<void>(); // Evento para cerrar el diálogo
  @Output() onConfirm = new EventEmitter<void>(); // Evento para confirmar la acción
  @ViewChild('dt1') dt!: Table;
  user: ICredencialUsuario = {};
  lstSemanas: ISemanasHappyMile[] = [];

  lstpostTexto: IpostTexto[] = [];
  selectedSemana: ISemanasHappyMile = {
    idx: 0,
    id_semana: 0,
    nom_semana: '',
    anio: 0,
    fec_inicio: '',
    fec_fin: '',
    clv_activo: 0
  };
  selectedSemanaReporte: ISemanasHappyMile = {
    idx: 0,
    id_semana: 0,
    nom_semana: '',
    anio: 0,
    fec_inicio: '',
    fec_fin: '',
    clv_activo: 0
  };
  num_anio_semana: number = 0;
  anio_filtro: any;
  anio_anterior:any;
  lstCiudadesPrincipales: ICiudadPrincipalHM[] = [];
  selectedCiudad: ICiudadPrincipalHM = {
    id: 0,
    nombre: '',
    clave_estado: ''
  };
  lstCiudad: ICiudadHM[] = [];
  anio: any = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
  fecha_dia: any = null;

  //validar este cambio
  //data: any;
  loading: boolean = false;
  //lstColaboradores: IChoferBonoPermanencia[] = [];
  lstColaboradores_Original: IChoferBonoPermanencia[] = [];
  lstColaboradores_SinCambios: IChoferBonoPermanencia[] = [];
  lstColaboradoresBono: any[] = [];
  lstRespondColaboradores: IChoferBonoPermanencia[] = [];
  lstBonoEnviar: any[] = [];
  lstBonoEnviar_Eliminar: IBonoPermanenciaEnviar[] = [];
  lstgetColaboradorCiudad: any[] = [];

  lstBonoPermanenciaPost: IBonoPermanenciaPost[] = [];
  lstbono = [
    { id: 1, nombre: '6 Meses' },
    { id: 2, nombre: '12 Meses' },
    { id: 0, nombre: 'Ninguno' }
  ];
  dsbGuardarTodo: boolean = true;
  errorEstatus: IErrorCode = {
    clv_status: false,
    message: '',
    errorCode: ''
  };

  //variblaes itzael cambio
  ultimoIdBonoSeleccionado: number | null = null;
  dspCliente: boolean = false;
  dspVerComentario: boolean = false;
  dspComentario: boolean = false;
  texto_operacion: string = '';
  num_operacion: number = 0;
  clv_operacion: number = 0;
  anio_asignado:any;
  habilitar_agregar: boolean = false;
  deshabilitarGuardarTodo: boolean = false;
  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };
  data: any;
  lstChoferes: InombreCompletoChofer[] = [];

  bonoPermaneciaForm: FormGroup = new FormGroup({

    chofer: new FormControl(0, [Validators.required]),
    id_bono: new FormControl(0),
    clv_retroactivo: new FormControl(0),

    desc_motivoCambio: new FormControl(''),
    clv_retrasaPago: new FormControl(0),
    clv_nuevoPorAdmn: new FormControl(0)


  });

  agregarBonoContratacionForm: FormGroup = new FormGroup({
    chofer: new FormControl(0, Validators.required),
    id_bono: new FormControl(0, [Validators.required, Validators.min(1)]),
  });

  agregarTextoForm: FormGroup = new FormGroup({
    chofer: new FormControl(0),
    respuesta: new FormControl('', [Validators.required, Validators.min(1)]),
    nom_ult_mod: new FormControl(''),
  });

  constructor(private bonocontratacionservice: BonoContratacionService,
    private auth: AuthService, private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private semanaService: SemanaService,
    private ciudadesprincipalesService: CiudadesprincipalesService,
    private bonopermanenciaService: BonopermanenciaService,
    private CiudadhmService: CiudadhmService,) {
    this.cargarInformacionUsuario();
  }
  ngOnInit(): void {
    this.cargarListadoDeSemanasAnio();
    this.cargarListadoDeCiudadesPrincipales();
    this.inicializarFechas();
    this.cargarCiudades();
  }
  EliminarBono(data: any) {
    this.confirmationService.confirm({
      message: 'Esta Seguro que Desea Eliminar Este Colaborador del Bono de Contratacion',
      header: 'Confirmacion',
      icon: 'pi pi-info-circle',
      accept: () => {
        let data_enviar = {
          cod_chofer: data.cod_chofer,
          cod_usuario: this.user.cod
        }
        this.bonocontratacionservice.DeleteBono(data_enviar).subscribe((resp) => {
          var respuesta = resp;
          setTimeout(() => {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Se Elimino de Forma Correcta', detail: 'La informacion se Elimino de forma correcta' });
          }, 200);
        })
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      },
    });

  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }
  closeDialog() {
    this.onClose.emit();
  }

  confirm() {
    this.onConfirm.emit();
  }

  ObtenerBonoContratacionSemanaCiudad() {

  }


  actualizarEstatusBoolean(lista: any[]) {
    if (lista.length > 0) {
      lista.map((valor) => {
        valor.clv_bono_cast = valor.clv_bono == 0 ? false : true;
      });
    }
  }

  guardarTodo() {
    if (this.lstColaboradoresBono.length > 0) {
      this.confirmationService.confirm({
        message: 'Los Colaboradores que figuren en el listado y anteriormente no hayan sido registrados en el numero de semana y año indicados, seran registrados con el beneficio' +
          ' correspondiente a la semana ' + this.selectedSemana.id_semana + ' del año ' + new Date(this.anio_filtro).getFullYear() + ' de la ciudad ' + this.selectedCiudad.id + ', asignado al pago unico del bono de contratacion.',
        header: 'Confirmacion',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.lstBonoEnviar = this.filtrarOperacionesNuevosDeBono(this.lstColaboradoresBono);
          this.loading = true;
          if (this.lstBonoEnviar.length > 0) {
            this.bonocontratacionservice.PostBonoContratacion(this.lstBonoEnviar).subscribe((resp) => {
              this.messageService.add({ key: 'msj', severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se registro de forma correcta' });
              this.dsbGuardarTodo = true;
              setTimeout(() => {
                this.cargarInformacionPersonalPorSemana();
                this.dsbGuardarTodo = false;
              }, 800);
            },
              (error) => {
                this.loading = false;
                this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error en Guardar Bono de Permancencia', detail: 'Contacte al Administrador del Sitio' });
              });
          } else {
            this.loading = false;
          }
          //this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
        },
        reject: (type: any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
              break;
            case ConfirmEventType.CANCEL:
              this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
              break;
          }
        },
      });
    }
  }

  filtrarOperacionesNuevosDeBono(lista: any[]): any[] {
    let listado: any[] = [];
    lista.map((valor) => {
      listado.push({
        cod_chofer: valor.cod_chofer,
        id_ciudad_HM: valor.id_ciudad_HM,
        id_ciudad_principal: valor.id_ciudad_principal,
        id_semana: this.selectedSemana.id_semana,
        num_anio: this.selectedSemana.anio,
        im_bonoTA: Number(valor.id_bono) === 1 ? 3000 : 0,
        clv_retroactivo: 0,
        clv_activo: 1,
        fec_alta: String(valor.fec_alta),
        cod_usuario: valor.clv_mod == 1 ? this.user.cod! : this.user.cod!,
        clv_cambioManual: valor.clv_cambioManual,
        desc_motivoCambio: valor.desc_motivoCambio,
        clv_retrasaPago: valor.clv_retrasaPago,
        clv_nuevoPorAdmn: valor.clv_nuevoPorAdmn,
        id_bono: Number(valor.id_bono)
      });
    });
    return listado.filter((x) => x.cod_usuario_registro != '');
  }

  obtenerBonoDelArregloOriginal(cod_chofer: number): IChoferBonoPermanencia {
    let objeto: IChoferBonoPermanencia = {
      idx: 0,
      id_permanencia: 0,
      cod_chofer: 0,
      nombres: '',
      apellido_paterno: '',
      apellido_materno: '',
      id_ciudad_HM: 0,
      nombre_ciudad_hm: '',
      id_ciudad_principal: 0,
      nombre_ciudad_principal: '',
      id_puesto: 0,
      desc_puesto: '',
      clv_activo: 0,
      id_bono: 0,
      desc_bono: '',
      clv_retroactivo: 0,
      clv_retroactivo_cast: false,
      fec_baja: '',
      clv_operacion: 0,
      clv_cambioManual: 0,
      desc_motivoCambio: '',
      clv_retrasaPago: 0,
      clv_nuevoPorAdmn: 0,
      nom_ult_mod: '',
      fec_alta: '',
      clv_mod: 0,
      clv_pagado: 0
    };
    this.lstColaboradores_SinCambios.map((valor) => {
      if (valor.cod_chofer == cod_chofer) {
        objeto = valor;
      }
    });
    return objeto;
  }

  filtrarOriginalesAnuevo(id_bono: number, objFiltrado: IChoferBonoPermanencia) {
    let clv_bono: number = 0;
    if (objFiltrado.id_bono != 0 && id_bono == 0) {
      //Se elimina si antes tenia bono y ahora va en 0
      clv_bono = 3;
    } else if (objFiltrado.id_bono == 0 && id_bono != 0) {
      //Se manda a agregar por que antes no tenia bono
      clv_bono = 1;
    } else if (objFiltrado.id_bono == 0 && id_bono == 0) {
      clv_bono = 0;
    }
    return clv_bono;
  }

  filtrarOriginalesAnuevoF(id_bono: number, objFiltrado: number) {
    let clv_bono: number = 0;
    if (objFiltrado != 0 && id_bono == 0) {
      //Se elimina si antes tenia bono y ahora va en 0
      clv_bono = 3;
    } else if (objFiltrado == 0 && id_bono != 0) {
      //Se manda a agregar por que antes no tenia bono
      clv_bono = 1;
    } else if (objFiltrado == 0 && id_bono == 0) {
      clv_bono = 0;
    }
    return clv_bono;
  }

  consultaSemanas() {

  }

  cargarListadoDeSemanasAnio() {
    this.lstSemanas = [];
    this.semanaService.listarSemanas(this.anio).subscribe((resp) => {
      this.lstSemanas = resp;
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
      });
  }

  cargarListadoDeCiudadesPrincipales() {
    this.lstCiudadesPrincipales = [];
    this.ciudadesprincipalesService.getListadoCiudadesPrincipales().subscribe((resp) => {
      this.lstCiudadesPrincipales = resp;
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
      });
  }
  dsblstCiudades:boolean=false;
  cargarCiudades() {
    this.dsblstCiudades = true;
    this.loading = true;
    this.CiudadhmService.getListadoCiudades().subscribe((resp) => {
      this.lstCiudad = resp.slice(); // Crear una copia independiente
      const index = this.lstCiudad.findIndex(item => item.nombre === 'TODAS LAS CIUDADES');
      if (index !== -1) {
        this.lstCiudad.splice(index, 1);
      }
      this.lstCiudad.push({ id: 0, nombre: 'TODAS LAS CIUDADES', clave_estado: '', id_ciudad_principal: 0, clave_tipoTransporte: 0 });
      setTimeout(() => {
        this.loading = false;
        this.dsblstCiudades = false;
      }, 300);
    },
    (error) => {
      this.loading = false;
      this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Ciudades');
    });
  }



  cargarInformacionPersonalPorSemana() {
    this.lstColaboradoresBono = [];
    this.lstColaboradores_Original = [];
    this.lstgetColaboradorCiudad=[];

    this.lstColaboradores_SinCambios = [];
    let id_semana = this.selectedSemana == null ? 0 : this.selectedSemana.id_semana;
    let id_ciudad_principal = this.selectedCiudad == null ? 0 : this.selectedCiudad.id;
    this.num_anio_semana = this.selectedSemana == null ? 0 : this.selectedSemana.anio;
    this.habilitar_agregar = true;
    if (id_semana != 0 && id_ciudad_principal != 0 && this.num_anio_semana != 0) {
      this.loading = true;
      this.bonocontratacionservice.getBonoPorSemanayCiudad(id_semana, this.selectedSemana.anio, id_ciudad_principal, this.user.cod!).subscribe((resp) => {

        this.lstColaboradoresBono = resp;

        this.lstColaboradores_Original = resp;
        this.lstColaboradores_SinCambios = this.lstColaboradores_Original;
        if (this.lstColaboradores_Original.length > 0) {
          this.dsbGuardarTodo = false;
        }
        setTimeout(() => {

          this.loading = false;
        },
          800);
      },
        (error) => {
          this.loading = false;
          this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
        });
    } else {
      this.mostrarMensajeDeAlerta();
    }

  }


  mostrarMensajeDeAlerta() {
    this.loading = false;
    this.messageService.add({
      key: 'confirm',
      sticky: true,
      severity: 'info',
      summary: 'Falta Semana y/o Ciudad',
      detail: 'Semana y Ciudad deben seleccionarse para consultar información.'
    });
  }

  cancelarMensajeConfirmacion() {
    this.messageService.clear('confirm');
    this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });

  }

  confirmarMensaje() {
    this.messageService.clear('confirm');
    this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
    this.anio_filtro = getYear;
  }

  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  getTargetDescription(ruta: ISemanasHappyMile) {
    let fec_inicio = new Date(ruta.fec_inicio);
    let fec_fin = new Date(ruta.fec_fin);
    //return ruta.fec_inicio.substring(0,10)+' | '+ruta.fec_fin.substring(0,10)+'';
    return this.formatearFechas(fec_inicio) + ' | ' + this.formatearFechas(fec_fin);
  }

  formatearFechas(fecha: Date): string {
    let fecha_Cast = '';
    var getYear = new Date(fecha).toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date(fecha).toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date(fecha).toLocaleDateString('es-MX', { day: '2-digit' });
    fecha_Cast = getDay + '/' + getMonth + '/' + getYear;
    return fecha_Cast;
  }

  //valida permisos
  validarClvOperacion() {
    this.clv_operacion = this.lstColaboradoresBono.length == 0 || typeof this.lstColaboradoresBono == 'undefined' ? 0 : this.lstColaboradoresBono[0].clv_operacion;
    if (this.clv_operacion == 3) {
      return true;
    }
    return false;

  }


  agregarColaborador() {
    this.lstgetColaboradorCiudad = [];
    this.texto_operacion = 'Agregar Bono por Contratacion';
    this.dspCliente = true;
    let id_semana = this.selectedSemana == null ? 0 : this.selectedSemana.id_semana;
    let id_ciudad_principal = this.selectedCiudad == null ? 0 : this.selectedCiudad.id;
    this.bonocontratacionservice.getListChoferesCiudad(id_semana, id_ciudad_principal).subscribe((resp) => {
      this.lstgetColaboradorCiudad = resp;
    });


  }
  agregarComentario(chofer: number) {
    this.agregarTextoForm.controls['chofer'].setValue(chofer);
    let resp = this.lstColaboradoresBono.filter((x) => x.cod_chofer == chofer)[0];
    this.lstColaboradoresBono.filter((x) => x.cod_chofer == chofer)[0].clv_mod = 1;
    this.agregarTextoForm.controls['respuesta'].setValue(resp.desc_motivoCambio);
    this.agregarTextoForm.controls['nom_ult_mod'].setValue(resp.nom_ult_mod);
    this.texto_operacion = 'Agregar comentario para asignar bono de permanencia';
    this.dspComentario = true;
  }

  cancelarVentanaModal() {
    this.dspCliente = false;
    this.dspVerComentario = false;
    this.dspComentario = false;
    this.texto_operacion = '';
    this.bonoPermaneciaForm.reset();
    this.agregarTextoForm.reset();
    this.agregarBonoContratacionForm.reset();
    this.lstgetColaboradorCiudad = [];
    this.agregarBonoContratacionForm.controls['chofer'].reset()
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
  }

  //sirve para selecionar el boton una sola vez no borrar
  seleccionarBono(idBono: number, cod_chofer: number) {
    this.agregarComentario(cod_chofer);
    if (idBono !== this.ultimoIdBonoSeleccionado) {
      // Actualizar el último id seleccionado
      this.ultimoIdBonoSeleccionado = idBono;
    }
  }

  // seleccionarBono(idBono: number) {
  //   this.ultimoIdBonoSeleccionado = idBono;
  //   this.agregarComentario();
  // }

  //boton que mostarra el pdialog con la informacion
  verMasPost(desc_motivoCambio: string, nom_ult_mod: string) {
    this.agregarTextoForm.controls['respuesta'].setValue(desc_motivoCambio);
    this.agregarTextoForm.controls['nom_ult_mod'].setValue(nom_ult_mod);
    this.dspVerComentario = true;
  }

  guardartexto() {
    let text = this.agregarTextoForm.controls['respuesta'].value;
    let colaborador = this.agregarTextoForm.controls['chofer'].value;
    let respIndex = this.lstColaboradoresBono.filter((x) => x.cod_chofer == colaborador)[0].desc_motivoCambio = text;
    this.cancelarVentanaModal();

  }
  cargarListadoDeSemanasAnio_cast(num_anio: any) {
    let anio_cast: any = 0;
    if (num_anio instanceof Date) {
      // will execute
      anio_cast = num_anio.getFullYear();
    } else {
      anio_cast = num_anio;
    }
    this.lstSemanas = [];
    this.semanaService.listarSemanas(anio_cast).subscribe((resp) => {
      this.lstSemanas = resp;
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error al cargar Semanas HM</b>. Contacte al Administrador.');
      });
  }


  colaboradorDuplicadoMSJ(respIndex: IrespondBonoPermanencia) {

    this.confirmationService.confirm({
      message: 'El Colaboradoe <b>' + respIndex.nom_completo + '</b> ya se encuentra asignado a la lista, favor de validar ',
      header: '<b>No</b> se permite Guardar',
      icon: 'pi pi-info-circle',
      accept: () => {

      }
    })
  }


  //   guarda la informacion del colaborador
  guardarInformacionDeColaborador() {
  //  let valorBono = this.lstbono.find((x) => x.id == this.agregarBonoContratacionForm.controls['id_bono'].value);
    let valorBono = this.lstbono.find(apoyo => apoyo.id === 1)
    let listado = this.lstColaboradoresBono;
    let chofer = this.agregarBonoContratacionForm.controls['chofer'].value;
    let respIndex = listado.filter((x) => x.cod_chofer == chofer);
    let addColaborador = this.lstgetColaboradorCiudad.filter((x) => x.cod_chofer == chofer)[0];
    if (respIndex.length == 0) {
      // Mostrar la ventana de confirmación
      this.confirmationService.confirm({
        message: '¿Está seguro que desea guardar la información del Colaborador <b>' + addColaborador.nom_completo + '</b> para aplicar al<b>  bono de contratacion</b>?',
        header: 'Confirmación de Guardado',
        icon: 'pi pi-info-circle',
        accept: () => {

          this.lstColaboradoresBono.push({
            idx: 0,
            id_permanencia: 0,
            cod_chofer: addColaborador.cod_chofer,
            nombres: addColaborador.nombres,
            apellido_paterno: addColaborador.apellido_paterno,
            apellido_materno: addColaborador.apellido_materno,
            id_ciudad_HM: addColaborador.id_ciudad_HM,
            nombre_ciudad_hm: addColaborador.nombre_ciudad_hm,
            id_ciudad_principal: addColaborador.id_ciudad_principal,
            nombre_ciudad_principal: addColaborador.nombre_ciudad_principal,
            id_puesto: addColaborador.id_puesto,
            desc_puesto: addColaborador.desc_puesto,
            clv_activo: addColaborador.clv_activo,
            id_bono: valorBono?.id,
            clv_retroactivo: 0,
            imp_bono:3000,
            fec_baja: addColaborador.fec_baja,
            clv_operacion: 0,
            clv_cambioManual: 0,
            desc_motivoCambio: 'Agregado por el administrador del sitio',
            clv_retrasaPago: 0,
            clv_nuevoPorAdmn: 1,
            nom_ult_mod: '',
            clv_pagado: 0
          });
          this.cancelarVentanaModal();

          this.messageService.add({
            key: 'msj',
            severity: 'success',
            summary: 'Confirmación',
            detail: 'La información del colaborador ha sido guardada exitosamente.'
          });

          // }
        },
        reject: (type: any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
              break;
            case ConfirmEventType.CANCEL:
              this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
              break;
          }
        }
      });
    } else {
      // Si falta información, puede simplemente enviar un mensaje genérico o manejarlo como creas conveniente
      this.messageService.add({
        key: 'msj',
        severity: 'error',
        summary: 'Error',
        detail: 'Favor  de validar que el colaborador no se encuentre en el listado original.'
      });
    }
  }
  deshabilitarbtnexportar: boolean = false;
  ButtonExportExcel: boolean = false;
  loading_reporte1: boolean = false;
  id_ciudad_principalrpt: number = 0;
  dbsReportes:boolean = false;
  abrirModalReportes(){
    this.dbsReportes = true
  }

  cancelarModalReportes(){
    this.dbsReportes =false
    this.id_ciudad_principalrpt = 0;
    this.selectedSemanaReporte = {
      idx: 0,
      id_semana: 0,
      nom_semana: '',
      anio: 0,
      fec_inicio: '',
      fec_fin: '',
      clv_activo: 0
    };
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_exportar_" + dateFormat + EXCEL_EXTENSION);
  }



  //exportarExcel() {
  //   this.deshabilitarbtnexportar = true;
  //   // if (this.id_ciudad_principalrpt == null || typeof this.id_ciudad_principalrpt === 'undefined' || !this.id_ciudad_principalrpt || this.selectedSemanaReporte.id_semana == null || typeof this.selectedSemanaReporte.id_semana === 'undefined') {
  //   //   this.mostrarDialogoInformativoSinInformacion('<b>Informativo</b> Debe seleccionar una semana para poder filtrar');
  //   //   this.deshabilitarbtnexportar = false;
  //   // } else {
  //   this.bonocontratacionservice.rptbono(this.selectedSemanaReporte.anio, this.selectedSemanaReporte.id_semana, this.id_ciudad_principalrpt,)
  //     .subscribe((resp: any) => {
  //       if (resp.length === 0 || typeof resp === 'undefined') {
  //         this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> debes de seleccionar parametros validos con registros existentes para poder exportar la informacion del Catalogo(Excel)');

  //         this.deshabilitarbtnexportar = false;
  //       } else {
  //         import("xlsx").then(xlsx => {
  //           const worksheet = xlsx.utils.json_to_sheet(resp);
  //           const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  //           const excelBuffer: any = xlsx.write(workbook, {
  //             bookType: "xlsx",
  //             type: "array"
  //           });

  //           this.saveAsExcelFile(excelBuffer, "Reporte_bono_contratacion" + '_' + 'semana' + '_' + this.selectedSemanaReporte.id_semana + '_' + 'añio' + '_' + this.selectedSemanaReporte.anio);
  //         });
  //         setTimeout(() => {
  //           this.ButtonExportExcel = false;
  //           this.deshabilitarbtnexportar = false;
  //           this.cancelarModalReportes()
  //         }, 1000);
  //       }
  //     },
  //       (error) => {
  //         this.ButtonExportExcel = false;
  //         this.deshabilitarbtnexportar = false;

  //         this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en emitir el reporte de Estatus de Facturación.' });
  //       });
  //   //}


  // }





  exportarExcel() {

    this.deshabilitarbtnexportar = true;
    let ciudad = this.lstCiudadesPrincipales.find(ciudad => ciudad.id = this.id_ciudad_principalrpt)

    this.bonocontratacionservice.rptbono(this.selectedSemanaReporte.anio, this.selectedSemanaReporte.id_semana, this.id_ciudad_principalrpt,)
      .subscribe((resp: any[]) => {
        if (resp.length === 0 || typeof resp === 'undefined') {
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> debes de seleccionar parametros validos con registros existentes para poder exportar la informacion del Catalogo(Excel)');

          this.deshabilitarbtnexportar = false;
        } else {
          import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(resp);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer: any = xlsx.write(workbook, {
              bookType: "xlsx",
              type: "array"
            });
            // Reporte_salario_promedio_monterreytp_semana_48_año_2024_exportar_2024-12-06.xlsx

            this.saveAsExcelFile(excelBuffer, "Reporte_bono_contratacion" + 'ciudad_'+ciudad?.nombre+'_semana' + '_' + this.selectedSemanaReporte.id_semana + '_' + 'año' + '_' + this.selectedSemanaReporte.anio);
          });
          setTimeout(() => {
            this.ButtonExportExcel = false;
            this.deshabilitarbtnexportar = false;
            this.cancelarModalReportes()
          }, 1000);
        }
      },
        (error) => {
          this.ButtonExportExcel = false;
          this.deshabilitarbtnexportar = false;

          this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en emitir el reporte de Estatus de Facturación.' });
        });
    //}


  }
}
