import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { MainComponent } from './shared/main/main.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ToastModule} from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { DatePipe } from '@angular/common';
import {ButtonModule} from 'primeng/button';
import { HttpClientModule } from '@angular/common/http';
import { RippleModule } from 'primeng/ripple';
import { ImageModule } from 'primeng/image';
import {MenuModule} from 'primeng/menu';
import {TooltipModule} from 'primeng/tooltip';
import {DropdownModule} from 'primeng/dropdown';
import { TagModule } from 'primeng/tag';
import {TableModule} from 'primeng/table';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DividerModule} from 'primeng/divider';
import {CheckboxModule} from 'primeng/checkbox';
import {ColorPickerModule} from 'primeng/colorpicker';
import { BlockUIModule } from 'primeng/blockui';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import {SidebarModule} from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';
import { MantenimientoComponent } from './shared/mantenimiento/mantenimiento.component';
import { ManualComponent } from './shared/manual/manual.component';
import { PrestamoComponent } from './shared/prestamo/prestamo.component';
import { BonopuntualidadComponent } from './shared/bonopuntualidad/bonopuntualidad.component';
import { IncapacidadComponent } from './shared/incapacidad/incapacidad.component';
import { SemanasComponent } from './shared/semanas/semanas.component';
import { DiasfestivosComponent } from './shared/diasfestivos/diasfestivos.component';
import { SalariosComponent } from './shared/salarios/salarios.component';
import { SalariodiarioComponent } from './shared/salariodiario/salariodiario.component';
import { AplicapuntualidadComponent } from './shared/aplicapuntualidad/aplicapuntualidad.component';
import { DescuentosvariosComponent } from './shared/descuentosvarios/descuentosvarios.component';
import { PrestamoviaticosComponent } from './shared/prestamoviaticos/prestamoviaticos.component';
import { HistoriconominaComponent } from './shared/historiconomina/historiconomina.component';
import { ConsultanominaComponent } from './shared/consultanomina/consultanomina.component';
import { InasistenciasComponent } from './shared/inasistencias/inasistencias.component';
import { BonopermanenciaComponent } from './shared/bonopermanencia/bonopermanencia.component';
import { CajaahorroComponent } from './shared/cajaahorro/cajaahorro.component';
import { ApoyoestatusforaneoComponent } from './shared/apoyoestatusforaneo/apoyoestatusforaneo.component';
import { BonoaguinaldoComponent } from './shared/bonoaguinaldo/bonoaguinaldo.component';
import { VacacionesComponent } from './shared/vacaciones/vacaciones.component';
import { PrimavacacionalComponent } from './shared/primavacacional/primavacacional.component';
import { BonorecomendacionComponent } from './shared/bonorecomendacion/bonorecomendacion.component';
import { InfonavitComponent } from './shared/infonavit/infonavit.component';
import { ConsultadeduccionesComponent } from './shared/consultadeducciones/consultadeducciones.component';
import { RecibodigitalComponent } from './shared/recibodigital/recibodigital.component';
import { FiniquitoComponent } from './shared/finiquito/finiquito.component';
import { CajaahorroanualComponent } from './shared/cajaahorroanual/cajaahorroanual.component';
import { ApoyosComponent } from './shared/apoyos/apoyos.component';
import { DiafestivoComponent } from './shared/diafestivo/diafestivo.component';
import { SalariopromediosemanalComponent } from './shared/salariopromediosemanal/salariopromediosemanal.component';
import { ValedespensaComponent } from './shared/valedespensa/valedespensa.component';
import { BonocontratacionComponent } from './shared/bonocontratacion/bonocontratacion.component';
import { BonodesempenoComponent } from './shared/bonodesempeno/bonodesempeno.component';
import { HijoschoferComponent } from './shared/hijoschofer/hijoschofer.component';
import { ApoyodiscapacidadComponent } from './shared/apoyodiscapacidad/apoyodiscapacidad.component';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    MantenimientoComponent,
    ManualComponent,
    PrestamoComponent,
    BonopuntualidadComponent,
    IncapacidadComponent,
    SemanasComponent,
    DiasfestivosComponent,
    SalariosComponent,
    SalariodiarioComponent,
    AplicapuntualidadComponent,
    DescuentosvariosComponent,
    PrestamoviaticosComponent,
    HistoriconominaComponent,
    ConsultanominaComponent,
    InasistenciasComponent,
    BonopermanenciaComponent,
    CajaahorroComponent,
    ApoyoestatusforaneoComponent,
    BonoaguinaldoComponent,
    VacacionesComponent,
    PrimavacacionalComponent,
    BonorecomendacionComponent,
    InfonavitComponent,
    ConsultadeduccionesComponent,
    RecibodigitalComponent,
    FiniquitoComponent,
    CajaahorroanualComponent,
    ApoyosComponent,
    DiafestivoComponent,
    SalariopromediosemanalComponent,
    ValedespensaComponent,
    BonocontratacionComponent,
    BonodesempenoComponent,
    HijoschoferComponent,
    ApoyodiscapacidadComponent,




  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    ToastModule,
    MessagesModule,
   // CaptchaModule,
    ButtonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RippleModule,
    ImageModule,
    MenuModule,
    TooltipModule,
    SidebarModule,
    DropdownModule,
    TableModule,
    CalendarModule,
    SliderModule,
    MultiSelectModule,
    ContextMenuModule,
    DialogModule,
    ProgressBarModule,
    InputTextModule,
    ConfirmDialogModule,
    DividerModule,
    CheckboxModule,
    ColorPickerModule,
    TagModule,
    BlockUIModule,
    TabViewModule,
    InputTextareaModule,
    FileUploadModule,
    SplitButtonModule,
    InputNumberModule,
    RadioButtonModule,
    ButtonModule

  ],
  providers: [DatePipe,ConfirmationService,MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
